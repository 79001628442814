import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  authenticated: false,
  redirectTo: '/',
};

export default handleActions(
  {
    [actions.authUser](state) {
      return { ...state, authenticated: true };
    },
    [actions.unAuthUser](state) {
      return { ...state, authenticated: false };
    },
    [actions.setRedirectTo](state, { payload }) {
      return { ...state, redirectTo: payload };
    },
  },
  initialState
);
