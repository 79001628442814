import { format } from 'date-fns';
import { reverse, sortBy } from 'lodash';
import accommodations from 'modules/accommodations';
import { createSelector } from 'reselect';

const getOperationsMap = state => state.settlement.operations;
export const getIsSettlementFetched = state => state.settlement.isSettlementFetched;
export const getFetchSettlementParams = state => state.settlement.fetchSettlementParams;
export const getIsSettlementComplete = state => state.settlement.isSettlementComplete;
export const getSummary = state => state.settlement.summary;
export const getInvoiceDetails = state => state.settlement.invoiceDetails;
export const getSelectedAccommodationId = state => state.settlement.fetchSettlementParams.accommodationId;
export const getSelectedStartDate = state => state.settlement.fetchSettlementParams.start;
export const getSelectedEndDate = state => state.settlement.fetchSettlementParams.end;

export const getOperations = createSelector(getOperationsMap, operationsMap => {
  const collection = Object.values(operationsMap).map(operation => ({
    ...operation.attributes,
    flowDirection: parseFloat(operation.attributes.netDueForOwner) >= 0 ? 'in' : 'out',
    key: operation.id,
  }));

  return reverse(sortBy(collection, item => item.effectiveAt));
});

export const getAccommodationsSelectOptions = createSelector(
  accommodations.selectors.getAccommodationsMap,
  accommodationsMap => {
    return Object.values(accommodationsMap).map(({ attributes }) => ({
      value: attributes.id,
      title: `${attributes.name} / ${attributes.locationName}`,
    }));
  }
);

const getSelectedAccommodation = createSelector(
  [accommodations.selectors.getAccommodationsMap, getSelectedAccommodationId],
  (accommodationsMap, id) => {
    if (id === null || id === undefined) {
      return null;
    }

    return accommodationsMap[id];
  }
);

const getFirstAccommodation = createSelector(
  [accommodations.selectors.getAccommodationsMap, getSelectedAccommodationId],
  (accommodationsMap, id) => {
    if (!accommodationsMap) {
      return null;
    }

    return Object.values(accommodationsMap)[0];
  }
);

export const getSettlementEffectivityPeriods = createSelector(getFirstAccommodation, accommodation => {
  if (!accommodation || !accommodation.attributes.settlementEffectivityPeriods) {
    return [];
  }
  return accommodation.attributes.settlementEffectivityPeriods.map(period => ({
    ...period,
    id: `${period.start}-${period.end}`,
  }));
});

const getSelectedPeriod = createSelector(
  [getSettlementEffectivityPeriods, getSelectedStartDate, getSelectedEndDate],
  (periods, start, end) => {
    return periods.find(period => period.id === `${start}-${end}`);
  }
);

export const getSelectedPeriodValue = createSelector(getSelectedPeriod, period => (period ? period.id : undefined));

export const getDecoratedInvoiceDetails = createSelector(
  [getInvoiceDetails, getSummary, getSelectedPeriod],
  (invoiceDetails, summary, period) => ({
    ...invoiceDetails,
    issueDate:
      invoiceDetails.issueDate && invoiceDetails.issuingPossible
        ? format(new Date(invoiceDetails.issueDate), 'dd.MM.yyyy')
        : '-',
    sellByDate: period && invoiceDetails.issuingPossible ? format(new Date(period.end), 'dd.MM.yyyy') : '-',
    netDue: summary && summary.dueTotal ? summary.dueTotal : null,
    netDuePositive: summary ? summary.totalPositive : null,
    periodStart: period ? format(new Date(period.start), 'dd.MM.yyyy') : null,
    periodEnd: period ? format(new Date(period.end), 'dd.MM.yyyy') : null,
  })
);

export const getAccommodationInitialPeriod = createSelector(getSettlementEffectivityPeriods, periods => {
  return periods[periods.length - 1];
});
