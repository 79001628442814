import app from 'modules/app';
import auth from 'modules/auth';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const { Layout } = app.components;

const ProtectedRoute = ({ children, ...rest }) => {
  const authenticated = useSelector(auth.selectors.getAuthenticated, shallowEqual);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          <Layout>{children}</Layout>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};

export default ProtectedRoute;
