import 'styled-components/macro';

import { CalendarOutlined, DollarOutlined, TeamOutlined } from '@ant-design/icons';
import { List as AntList, Popconfirm, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import * as actions from '../../actions';
import { StatusOption } from '../../helpers';

const { Paragraph, Text, Link } = Typography;

const StaysList = ({ items, loading }) => {
  const { t } = useTranslation(['common']);

  const dispatch = useDispatch();

  const handleOnCancelClick = useCallback(
    stay => _event => {
      dispatch(actions.cancelStay(stay));
    },
    [dispatch]
  );

  const handleOnChangeClick = useCallback(
    stay => event => {
      event.preventDefault();
      dispatch(actions.setChangingStaySelectedAccommodationId(stay.accommodationId));
      dispatch(actions.setChangingStayBookingId(stay.id));
    },
    [dispatch]
  );

  const handleNoteChange = useCallback(
    id => content => {
      dispatch(actions.setNote({ id: id, content: content }));
    },
    [dispatch]
  );

  return (
    <AntList
      dataSource={items}
      itemLayout="vertical"
      loading={loading}
      pagination={false}
      renderItem={item => (
        <AntList.Item
          key={item.number}
          actions={[
            <StatusOption key="status-option" status={item.status} />,
            <span key="dates">
              <CalendarOutlined
                css={`
                  margin-right: 0.8rem;
                `}
              />
              {`${format(new Date(item.arrival), 'dd-MM-yyyy')} - ${format(new Date(item.departure), 'dd-MM-yyyy')}`}
            </span>,
            <span key="guestCount">
              <TeamOutlined
                css={`
                  margin-right: 0.8rem;
                `}
              />
              {item.guestCount}
            </span>,
            <Tooltip key="service-charge-tooltip" title="Koszt obsługi pobytu">
              <span>
                <DollarOutlined
                  css={`
                    margin-right: 0.8rem;
                  `}
                />
                {item.cost}
              </span>
            </Tooltip>,
            item.status === 'confirmed' ? (
              <Link href="#" onClick={handleOnChangeClick(item)}>
                {t('actions.change')}
              </Link>
            ) : null,
            item.status === 'confirmed' ? (
              <Popconfirm
                key="cancel-stay-popconfirm"
                cancelText={t('no')}
                okText={t('yes')}
                title={t('modules.stays.messages.cancelStay.confirm')}
                onConfirm={handleOnCancelClick(item)}
              >
                <a href="#">
                  <Text type="danger">{t('actions.cancel')}</Text>
                </a>
              </Popconfirm>
            ) : null,
          ]}
        >
          <AntList.Item.Meta
            description={
              <Paragraph editable={{ onChange: handleNoteChange(item.id) }}>
                {item.notes || t('modules.stays.noNotes')}
              </Paragraph>
            }
            title={`${t('accommodationNameWithPrefix', { name: item.accommodationName })} (${item.number})`}
          />
        </AntList.Item>
      )}
      size="large"
    />
  );
};

export default StaysList;
