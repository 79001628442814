import currency from 'currency.js';
import { each, get, isArray, set } from 'lodash';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const getValidationProps = ({ error, asyncValidating, validating }) => {
  const getValidateStatus = () => {
    if (error) {
      return 'error';
    }
    if (asyncValidating || validating) {
      return 'validating';
    }

    return 'success';
  };

  return {
    help: error || null,
    validateStatus: getValidateStatus(),
  };
};

export const getInitials = name => {
  const nameParts = name.split(' ');
  const firstNameInitial = nameParts[0][0];
  const lastNameInitial = nameParts[nameParts.length - 1][0];

  return firstNameInitial + lastNameInitial;
};

export const formatErrors = errors => {
  if (!Object.keys(errors).length) {
    return {};
  }

  const newErrors = {};

  each(errors, (value, property) => {
    if (isArray(value)) {
      set(newErrors, [property], value.sort()[0]);
    } else {
      each(value, (deepValue, deepProperty) => {
        const newError = deepValue.sort()[0];
        set(newErrors, [property, deepProperty], newError);
      });
    }
  });

  return newErrors;
};

export const getFieldValidateStatus = ({ field, form }) => {
  const fieldName = field.name;
  const { isValid, touched, errors, isValidating } = form;

  if (!isValid && get(touched, fieldName) && !get(errors, fieldName)) {
    return null;
  }

  if (!get(touched, fieldName)) {
    return null;
  }

  if (isValidating) {
    return 'validating';
  }

  if (get(errors, fieldName)) {
    return 'error';
  }

  return 'success';
};

export const getFieldHelp = fieldProps => {
  const { field, form } = fieldProps;
  const fieldName = field.name;
  const { touched, errors } = form;

  const fieldValidationStatus = getFieldValidateStatus(fieldProps);

  if (fieldValidationStatus === 'error' && get(touched, fieldName)) {
    return get(errors, fieldName);
  }

  return null;
};

export const getFieldHasFeedback = ({ field, form }) => {
  const fieldName = field.name;
  const { submitCount, touched } = form;
  const submitted = submitCount > 0;

  return submitted || get(touched, fieldName);
};

export const formatMoney = (amount, cur = 'PLN') => {
  const parsedAmount = currency(amount);
  return currency(parsedAmount, {
    separator: '.',
    decimal: ',',
    symbol: cur,
    pattern: '# !',
    negativePattern: '-# !',
  })
    .format()
    .trim();
};

export const filterOptionsBy = (inputValue, path) =>
  path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

export const alphabeticalSorter = property => (a, b) => {
  if (a[property] < b[property]) {
    return -1;
  }

  return a[property] > b[property] ? 1 : 0;
};

export const romanize = input => {
  const num = Number(input);
  if (isNaN(num)) {
    throw new Error(`"${input}" is not a number that can be converted to Roman numerals.`);
  }
  if (!Number.isInteger(num)) {
    throw new Error(`Only integers can be converted to Roman numerals.`);
  }
  if (num < 0) {
    throw new Error(`Only positive numbers can be converted to Roman numerals.`);
  }
  const digits = String(num).split('');
  const numerals = [
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
  ];
  let roman = '';
  [0, 1, 2].forEach(numeralSet => {
    const currentDigit = Number(digits.pop());
    if (isNaN(currentDigit)) {
      return;
    }
    const numeralsIndex = currentDigit + numeralSet * 10;
    const numeral = numerals[numeralsIndex];
    if (!numeral) {
      return;
    }
    roman = numeral + roman;
  });
  const thousands = Number(digits.join(''));
  const thousandsNumerals = 'M'.repeat(thousands);

  return thousandsNumerals + roman;
};

export const capitalizeFirstWord = string => {
  const [firstWord, ...rest] = string.split(' ');

  return [firstWord.toUpperCase(), ...rest].join(' ');
};
