import axios from 'axios';
import cleanDeep from 'clean-deep';
import Cookies from 'js-cookie';
import qs from 'qs';

const API_URL = `${process.env.REACT_APP_API_URL}/api/owner/v1`;

const initializeApi = () => {
  const defaultOptions = {
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'X-Key-Inflection': 'camel',
      Locale: process.env.REACT_APP_DEFAULT_LOCALE,
    },
    paramsSerializer: params =>
      qs.stringify(cleanDeep(params), { arrayFormat: 'brackets', encodeValuesOnly: true, indices: false }),
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        Cookies.remove('revenuo-owner-jwt');
        window.location.href = '/login';
      }

      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use(config => {
    const token = Cookies.get('revenuo-owner-jwt');

    if (token) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }

    return config;
  });

  return instance;
};

export default initializeApi();
