export const CANCEL_STAY = 'stays/CANCEL_STAY';
export const SET_IS_COLLECTION_FETCHED = 'stays/SET_IS_COLLECTION_FETCHED';
export const SET_NON_CHARGEABLE_NIGHTS = 'stays/SET_NON_CHARGEABLE_NIGHTS';
export const SET_NOTE = 'stays/SET_NOTE';
export const SET_SELECTED_ACCOMMODATION_ID = 'stays/SET_SELECTED_ACCOMMODATION_ID';
export const SET_STAYS = 'stays/SET_STAYS';

export const SET_CHANGING_STAY_BOOKING_ID = 'stays/SET_CHANGING_STAY_BOOKING_ID';
export const RESET_CHANGING_STAY_BOOKING_ID = 'stays/RESET_CHANGING_STAY_BOOKING_ID';
export const SET_CHANGING_STAY_SELECTED_ACCOMMODATION_ID = 'stays/SET_CHANGING_STAY_SELECTED_ACCOMMODATION_ID';
