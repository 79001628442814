import { connect } from 'formik';
import { debounce, isEqual, pick } from 'lodash';
import { useEffect, useState } from 'react';

const FormikEffect = ({ formik, debounceTime, schema, onChange, keysToListen = null }) => {
  const value = formik.values;
  const [values, setValues] = useState(value);

  useEffect(() => {
    const change = window.Cypress ? onChange : debounce(onChange, debounceTime || 1000);
    const isValid = schema ? schema.isValidSync(value) : formik.isValid;

    const valuesToCompare = keysToListen ? pick(values, keysToListen) : values;
    const newValuesToCompare = keysToListen ? pick(value, keysToListen) : value;

    if (!isEqual(valuesToCompare, newValuesToCompare) && isValid) {
      setValues(value);
      change(value);
    }
  }, [debounceTime, formik.isValid, onChange, schema, value, values]);

  return null;
};

FormikEffect.defaultProps = {
  onChange: () => null,
};

export default connect(FormikEffect);
