import uuid from '_lib/uuid';
import cleanDeep from 'clean-deep';
import qs from 'qs';
import { BEGIN, COMMIT, REVERT } from 'redux-optimistic-ui';
import api from 'services/api';

import { apiEnd, apiError, apiStart } from './actions';

// All my redux action types that are optimistic have the following suffixes, yours may vary
const _SUCCESS = '_SUCCESS';
const _FAILURE = '_FAILURE';

export default ({ dispatch }) =>
  next =>
  action => {
    const { type, meta, payload } = action;

    // For actions that have a high probability of failing, I don't set the flags
    if (!meta || !meta.isOptimistic) return next(action);

    const { url, method, data } = meta;
    const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    const transactionID = uuid.v4();

    // Extend the action.meta to let it know we're beginning an optimistic update
    next({ ...action, meta: { optimistic: { type: BEGIN, id: transactionID } } });

    dispatch(apiStart());

    return api
      .request({
        url,
        method,
        [dataOrParams]: cleanDeep(data),
        paramsSerializer: params => qs.stringify(cleanDeep(params), { arrayFormat: 'brackets', encode: false }),
      })
      .then(() => {
        next({
          type: type + _SUCCESS,
          payload,
          meta: {
            optimistic: { type: COMMIT, id: transactionID },
          },
        });
      })
      .catch(error => {
        dispatch(apiError(error));

        next({
          type: type + _FAILURE,
          payload,
          error,
          meta: {
            optimistic: { type: REVERT, id: transactionID },
          },
        });

        throw error;
      })
      .finally(() => {
        dispatch(apiEnd());
      });
  };
