import { message } from 'antd';
import I18n from 'i18n';
import normalize from 'json-api-normalizer';
import { createAction } from 'redux-actions';
import api from 'services/api';

import * as types from './actionTypes';

export const setBookings = createAction(types.SET_BOOKINGS);
export const setIsCollectionFetched = createAction(types.SET_IS_COLLECTION_FETCHED);
export const setOptions = createAction(types.SET_OPTIONS);

export const fetchBookings = () => dispatch => {
  api
    .get('/bookings')
    .then(({ data }) => {
      const normalizedData = normalize(data);

      if (data.data.length) {
        dispatch(setBookings(normalizedData.booking));
      }

      dispatch(setOptions({ option: 'statuses', value: data.meta.statuses }));
      dispatch(setIsCollectionFetched(true));
    })
    .catch(() => {
      message.error(I18n.t('common:modules.bookingsList.messages.fetchBookings.failure'));
    });
};
