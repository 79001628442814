import { Button, Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Page404 = () => {
  const { t } = useTranslation(['common']);

  return (
    <Result
      extra={
        <Link to="/">
          <Button type="primary">{t('common:404.home')}</Button>
        </Link>
      }
      status="404"
      subTitle={t('common:404.sorry')}
      title="404"
    />
  );
};

export default Page404;
