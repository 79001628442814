import accommodations from 'modules/accommodations';
import account from 'modules/account';
import auth from 'modules/auth';
import bookings from 'modules/bookings';
import settlement from 'modules/settlement';
import stays from 'modules/stays';
import { combineReducers } from 'redux';

const appReducer = combineReducers({
  [accommodations.constants.NAME]: accommodations.reducer,
  [account.constants.NAME]: account.reducer,
  [auth.constants.NAME]: auth.reducer,
  [bookings.constants.NAME]: bookings.reducer,
  [settlement.constants.NAME]: settlement.reducer,
  [stays.constants.NAME]: stays.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === auth.types.UNAUTH_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
