import 'styled-components/macro';

import { CalendarTwoTone, CarryOutTwoTone } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Tabs } from 'antd';
import app from 'modules/app';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import {
  getCanceledStays,
  getCompletedStays,
  getConfirmedStays,
  getIsCollectionFetched,
  getNonChargeableNights,
  getStaysInFutureCount,
} from '../../selectors';
import ChangeStay from './ChangeStay';
import PlanStay from './PlanStay';
import StaysList from './StaysList';

const Layout = app.components.Layout;
const { TabPane } = Tabs;

const List = () => {
  const { t } = useTranslation(['common']);

  const dispatch = useDispatch();
  const isCollectionFetched = useSelector(getIsCollectionFetched, shallowEqual);
  const nonChargeableNights = useSelector(getNonChargeableNights, shallowEqual);
  const plannedStaysCount = useSelector(getStaysInFutureCount, shallowEqual);
  const confirmedStays = useSelector(getConfirmedStays, shallowEqual);
  const completedStays = useSelector(getCompletedStays, shallowEqual);
  const canceledStays = useSelector(getCanceledStays, shallowEqual);

  useEffect(() => {
    dispatch(actions.fetchStays());
    dispatch(actions.fetchAccommodations());
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('appName')} - ${t('modules.stays.title')}`}</title>
      </Helmet>
      <Layout.PageHeader extra={[<PlanStay key="plan-stay" />]} title={t('modules.stays.title')}>
        <ChangeStay />
        <Row type="flex">
          <Statistic
            prefix={
              <CarryOutTwoTone
                css={`
                  margin-right: 0.8rem;
                `}
              />
            }
            style={{
              margin: '0 32px',
            }}
            title={t('modules.stays.plannedStays')}
            value={plannedStaysCount}
          />
          {nonChargeableNights.total !== null && nonChargeableNights.total > 0 ? (
            <Statistic
              prefix={
                <CalendarTwoTone
                  css={`
                    margin-right: 1rem;
                  `}
                />
              }
              suffix={`/ ${nonChargeableNights.total}`}
              title={t('modules.stays.remainingFreeNightsThisYear')}
              value={nonChargeableNights.remaining}
            />
          ) : null}
        </Row>
      </Layout.PageHeader>
      <Layout.ContentInner>
        <Row>
          <Col span={24}>
            <Card bordered={false} loading={!isCollectionFetched}>
              <Tabs defaultActiveKey="confirmed">
                <TabPane
                  key="confirmed"
                  tab={`${t('modules.stays.statuses.plural.confirmed')} (${confirmedStays.length})`}
                  forceRender
                >
                  <StaysList items={confirmedStays} />
                </TabPane>
                <TabPane
                  key="completed"
                  tab={`${t('modules.stays.statuses.plural.completed')} (${completedStays.length})`}
                  forceRender
                >
                  <StaysList items={completedStays} />
                </TabPane>
                <TabPane
                  key="cancelled"
                  tab={`${t('modules.stays.statuses.plural.canceled')} (${canceledStays.length})`}
                  forceRender
                >
                  <StaysList items={canceledStays} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Layout.ContentInner>
    </>
  );
};

export default memo(List);
