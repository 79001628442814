import {
  BarsOutlined,
  CalendarOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  FileOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

const noVatTnvoiceUrl = `${process.env.PUBLIC_URL}/faktura_bez_vat.xls`;
const vatInvoiceUrl = `${process.env.PUBLIC_URL}/faktura_vat.xls`;

const Navigation = () => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const location = useLocation();

  const pathElements = location.pathname.split('/').filter(i => i);

  const handleMenuClick = useCallback(
    event => {
      switch (event.key) {
        case 'vat-invoice':
          return false;
        case 'no-vat-invoice':
          return false;
        default:
          return history.push(`/${event.key}`);
      }
    },
    [history]
  );

  return (
    <Menu
      mode="inline"
      selectable={false}
      selectedKeys={pathElements}
      theme="dark"
      forceSubMenuRender
      onClick={handleMenuClick}
    >
      <Menu.Item key="accommodations">
        <EnvironmentOutlined />
        <span className="nav-text">{t('menuNames.accommodations')}</span>
      </Menu.Item>
      <Menu.Item key="bookings-list">
        <BarsOutlined />
        <span className="nav-text">{t('menuNames.bookingsList')}</span>
      </Menu.Item>
      <Menu.Item key="your-stays">
        <CalendarOutlined />
        <span className="nav-text">{t('menuNames.yourStays')}</span>
      </Menu.Item>
      <Menu.Item key="settlement">
        <DollarOutlined />
        <span className="nav-text">{t('menuNames.settlement')}</span>
      </Menu.Item>
      <Menu.Item key="account-settings">
        <SettingOutlined />
        <span className="nav-text">{t('menuNames.accountSettings')}</span>
      </Menu.Item>
      <Menu.SubMenu
        key="templates"
        title={
          <>
            <FileOutlined />
            <span className="nav-text">{t('menuNames.templates')}</span>
          </>
        }
      >
        <Menu.Item key="vat-invoice">
          <a href={vatInvoiceUrl} download>
            {t('menuNames.vatInvoice')}
          </a>
        </Menu.Item>
        <Menu.Item key="no-vat-invoice">
          <a href={noVatTnvoiceUrl} download>
            {t('menuNames.noVatInvoice')}
          </a>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default memo(Navigation);
