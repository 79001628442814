import 'styled-components/macro';

import { Col, Layout, Row } from 'antd';
import React from 'react';

import UserMenu from './UserMenu';

const Header = ({ logout }) => {
  return (
    <Layout.Header
      css={`
        display: flex;
        align-items: center;
        padding: 0 1.6rem;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
        background: #fff;
        z-index: 1;
        height: 6rem;
      `}
    >
      <Row
        css={`
          width: 100%;
        `}
        justify="end"
        type="flex"
      >
        <Col>
          <UserMenu logout={logout} />
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
