import produce from 'immer';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  byId: {},
  ids: [],
  isCollectionFetched: false,
};

const reducer = handleActions(
  {
    [actions.setIsCollectionFetched](state) {
      return produce(state, newState => {
        newState.isCollectionFetched = true;
      });
    },
    [actions.setAccommodations](state, { payload }) {
      return produce(state, newState => {
        newState.byId = payload;
      });
    },
    [actions.setIds](state, { payload }) {
      return produce(state, newState => {
        newState.ids = payload;
      });
    },
  },
  initialState
);

export default reducer;
