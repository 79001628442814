import { createAction } from 'redux-actions';

import { API, API_END, API_FAILURE, API_START } from './types';

export const apiRequest = createAction(
  API,
  ({
    url = '',
    method = 'GET',
    data = null,
    onSuccess = () => ({}),
    onFailure = () => ({}),
    label = '',
    meta = {},
  }) => ({
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    meta,
  }),
  ({ meta }) => ({ ...meta })
);

export const apiStart = createAction(API_START);
export const apiEnd = createAction(API_END);
export const apiError = createAction(API_FAILURE);
