import 'styled-components/macro';

import uuid from '_lib/uuid';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import * as hooks from '../hooks';
import StayForm from './StayForm';

const PlanStay = () => {
  const { t } = useTranslation(['common']);
  const [isVisible, width, handleModalOpen, handleModalClose] = hooks.useModal();
  const bookingId = useMemo(() => uuid.v4(), [isVisible]);
  const stayId = useMemo(() => uuid.v4(), [isVisible]);

  return (
    <>
      <Button key="1" icon={<PlusOutlined />} type="primary" onClick={handleModalOpen}>
        {t('actions.planYourStay')}
      </Button>
      <Modal
        key={bookingId}
        footer={false}
        maskClosable={false}
        title={t('modules.planStay.title')}
        visible={isVisible}
        width={width}
        onCancel={handleModalClose}
      >
        <StayForm bookingId={bookingId} closeModal={handleModalClose} id={stayId} />
      </Modal>
    </>
  );
};

export default memo(PlanStay);
