import 'styled-components/macro';

import { Alert, Descriptions, Spin, Typography } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import * as selectors from '../../selectors';
import Amount from './Amount';

const InvoiceDetails = ({ loading }) => {
  const { t } = useTranslation(['common']);
  const invoiceDetails = useSelector(selectors.getDecoratedInvoiceDetails, shallowEqual);

  if (!invoiceDetails) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Descriptions
        column={3}
        css={`
          margin-bottom: 3rem;
          .ant-descriptions-item-label {
            white-space: normal;
          }
        `}
        layout="vertical"
        size="small"
        title={t('modules.settlement.invoiceDetails.title')}
        bordered
      >
        <Descriptions.Item label={t('modules.settlement.invoiceDetails.status')} span={3}>
          <Alert
            css={'text-align: left;'}
            message={
              invoiceDetails.issuingPossible
                ? t('modules.settlement.invoiceDetails.complete')
                : t('modules.settlement.invoiceDetails.issuingImpossible')
            }
            type={invoiceDetails.issuingPossible ? 'success' : 'error'}
            showIcon
          />
        </Descriptions.Item>
        <Descriptions.Item label={t('modules.settlement.invoiceDetails.serviceName')} span={3}>
          {invoiceDetails.issuingPossible
            ? t('modules.settlement.invoiceDetails.serviceNameText', {
                start: invoiceDetails.periodStart,
                end: invoiceDetails.periodEnd,
              })
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={t('modules.settlement.invoiceDetails.issueDate')}>
          <Typography.Text>{invoiceDetails.issueDate}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={t('modules.settlement.invoiceDetails.sellByDate')}>
          <Typography.Text>{invoiceDetails.sellByDate}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>{t('modules.settlement.invoiceDetails.netDue')}</Typography.Text>}
        >
          {invoiceDetails.issuingPossible ? (
            <Amount
              currency="PLN"
              direction={invoiceDetails.netDuePositive ? 'in' : 'out'}
              value={invoiceDetails.netDue}
              noSign
            />
          ) : (
            '-'
          )}
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  );
};

export default memo(InvoiceDetails);
