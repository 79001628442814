import 'styled-components/macro';

import { EnvironmentOutlined, HomeOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Card, Input } from 'antd';
import { message } from 'antd';
import React, { createRef, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const { Meta } = Card;

const AffiliateLink = memo(({ url }) => {
  const { t } = useTranslation(['common']);

  const input = createRef();

  const handleClick = useCallback(() => {
    input.current.select();
    input.current.setSelectionRange(0, 99999);

    document.execCommand('copy');
    message.success(t('modules.accommodations.messages.copyLink.success'));
  }, [input]);

  const handleVisibleInputFocus = useCallback(e => {
    e.target.select();
  }, []);

  return (
    <div
      css={`
        padding: 0 1rem;
      `}
    >
      <input
        ref={input}
        css={`
          opacity: 0;
          position: absolute;
          pointer-events: none;
        `}
        type="text"
        value={url}
        readOnly
      />
      <Input
        css={`
          margin-bottom: 1rem;
        `}
        value={url}
        readOnly
        onFocus={handleVisibleInputFocus}
      />
      <Button icon={<LinkOutlined />} type="primary" block onClick={handleClick}>
        {t('modules.accommodations.copyAffiliateLink')}
      </Button>
    </div>
  );
});

const Description = ({ propertyName, locationName }) => (
  <>
    <span>
      <HomeOutlined />
      &ensp;
      {propertyName}
    </span>
    <br />
    <span>
      <EnvironmentOutlined />
      &ensp;
      {locationName}
    </span>
  </>
);

const Item = ({ item }) => {
  const { t } = useTranslation(['common']);
  const name = t('accommodationNameWithPrefix', { name: item.name });

  const handleImageClick = useCallback(() => {
    window.open(item.url, '_blank');
  }, [item]);

  return (
    <Card
      actions={item.affiliateUrl ? [<AffiliateLink key="copy-affiliate-link" url={item.affiliateUrl} />] : []}
      cover={<img alt={name} src={item.image} onClick={handleImageClick} />}
      css={`
        margin-bottom: 2rem;
      `}
      hoverable
    >
      <Meta
        description={<Description locationName={item.locationName} propertyName={item.propertyName} />}
        title={
          <a href={item.url} rel="noopener noreferrer" target="_blank">
            {name}
          </a>
        }
      />
    </Card>
  );
};

export default memo(Item);
