import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  color: #fff;
  width: auto;
  font-size: 2.8rem;
  font-family: Open Sans, sans-serif;
  line-height: 1.1;
`;

const Brand = styled.div`
  display: inline-block;
  width: auto;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-weight: 300;
  margin-right: ${({ collapsed }) => (collapsed ? '-1rem' : 0)};
`;

const Subtitle = styled.div`
  display: block;
  letter-spacing: 0.05rem;
  opacity: 0.7;
  font-size: 40%;
  font-weight: 300;
  text-align: right;
  width: 100%;
  margin-top: 0.05rem;
`;

const Logo = ({ collapsed }) => {
  const { t } = useTranslation(['common']);

  const brandName = collapsed ? t('brandName').charAt(0) : t('brandName');

  return (
    <Container>
      <Brand collapsed={collapsed}>{brandName}</Brand>
      {collapsed ? null : <Subtitle>Owner</Subtitle>}
    </Container>
  );
};

export default memo(Logo);
