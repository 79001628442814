import { message } from 'antd';
import I18n from 'i18n';
import Cookies from 'js-cookie';
import { noop } from 'lodash';
import { createAction } from 'redux-actions';
import api from 'services/api';
import URI from 'urijs';

import * as types from './actionTypes';
import * as selectors from './selectors';

export const authUser = createAction(types.AUTH_USER);
export const unAuthUser = createAction(types.UNAUTH_USER);
export const setRedirectTo = createAction(types.SET_REDIRECT_TO);

export const signIn =
  ({ data, history = {}, cb = noop }) =>
  (dispatch, getState) => {
    api
      .post('/owner-token', { auth: data })
      .then(({ data: { jwt, name } }) => {
        const { token } = jwt;
        const { exp } = jwt.payload;

        const state = getState();
        const redirectToFromState = selectors.getRedirectTo(state);
        const redirectToURI = URI(redirectToFromState.includes('login') ? '/' : redirectToFromState);

        Cookies.set('revenuo-owner-jwt', token, { expires: new Date(exp * 1000) });
        localStorage.setItem('user', name);
        dispatch(authUser());
        history.push(redirectToURI.path());
      })
      .catch(() => {
        message.error(I18n.t('common:login.error'));
      })
      .finally(() => {
        cb();
      });
  };

export const logout = () => dispatch => {
  Cookies.remove('revenuo-owner-jwt');
  dispatch(unAuthUser());
};
