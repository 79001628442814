import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${props => props.theme.colors.blue5};
  /* background: radial-gradient(circle, ${props => props.theme.colors.blue4}, ${props => props.theme.colors.blue7}); */
  background: radial-gradient(circle, #334453, #001528);

  .ant-spin-container,
  .ant-spin-nested-loading {
    height: 100%;
  }
`;

export const Content = styled.div`
  display: inline-block;
  height: auto;
  margin-top: -15vh;
`;

export const FormContainer = styled.div`
  display: block;
  width: 32rem;
  padding: 4rem 3.6rem;
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 15rem rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;

  button {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  display: block;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0.8rem;

  > div {
    font-size: 3.5rem;
  }
`;
