import { createSelector } from 'reselect';

const getAccountDetails = state => state.account.details.attributes;
export const getIsAccountFetched = state => state.account.isAccountFetched;

export const getInitialEditDetailsFormValues = createSelector(getAccountDetails, details => ({
  email: details.email,
  name: details.name,
  companyName: details.companyName,
  vatId: details.vatId,
  phone: details.phone,
  addressAttributes: {
    street: details.street,
    city: details.city,
    zip: details.zip,
    country: details.country,
  },
}));
