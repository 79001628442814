import { compact } from 'lodash';
import optimisticAPIMiddleware from 'middlewares/optimisticAPI';
import reducers from 'reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reduxThunk from 'redux-thunk';
import sagas from 'sagas';

export default function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();

  let toCompose = [
    applyMiddleware(reduxThunk, optimisticAPIMiddleware, sagaMiddleware),
    /* eslint-disable no-underscore-dangle */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    /* eslint-enable */
  ];

  toCompose = compact(toCompose);
  const enhancer = compose(...toCompose);
  const store = createStore(reducers, initialState, enhancer);

  sagaMiddleware.run(function* saga() {
    yield sagas();
  });

  return store;
}
