import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  isCollectionFetched: false,
  bookings: {},
  options: {
    statuses: [],
  },
};

export default handleActions(
  {
    [actions.setIsCollectionFetched](state) {
      return { ...state, isCollectionFetched: true };
    },
    [actions.setBookings](state, { payload }) {
      return { ...state, bookings: payload };
    },
    [actions.setOptions](state, { payload }) {
      return { ...state, options: { ...state.options, [payload.option]: payload.value } };
    },
  },
  initialState
);
