import { ConfigProvider } from 'antd';
import plPL from 'antd/es/locale/pl_PL';
import accommodations from 'modules/accommodations';
import account from 'modules/account';
import app from 'modules/app';
import auth from 'modules/auth';
import bookings from 'modules/bookings';
import settlement from 'modules/settlement';
import stays from 'modules/stays';
import ProtectedRoute from 'ProtectedRoute';
import React from 'react';
import CacheBuster from 'react-cache-buster';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import configureStore from 'store';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';

import { version } from '../package.json';
import validateMessages from './_lib/validateMessages';
import RouterListener from './RouterListener';

const localesMap = {
  pl: plPL,
};

const store = configureStore();

const Login = auth.components.Login;
const Settings = account.components.Settings;
const Bookings = bookings.components.List;
const Stays = stays.components.List;
const Settlement = settlement.components.List;
const Accommodations = accommodations.components.List;
const Error404 = app.components.Page404;

const App = () => {
  const isProduction = process.env.NODE_ENV === 'production';

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <CacheBuster currentVersion={version} isEnabled={isProduction} isVerboseMode={false}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConfigProvider form={{ validateMessages }} locale={localesMap[language]}>
            <Helmet>
              <title>{t('common:appName')}</title>
            </Helmet>
            <Router>
              <RouterListener>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <ProtectedRoute path="/account-settings" exact>
                    <Settings />
                  </ProtectedRoute>
                  <ProtectedRoute path="/bookings-list" exact>
                    <Bookings />
                  </ProtectedRoute>
                  <ProtectedRoute path="/your-stays" exact>
                    <Stays />
                  </ProtectedRoute>
                  <ProtectedRoute path="/settlement" exact>
                    <Settlement />
                  </ProtectedRoute>
                  <ProtectedRoute path="/accommodations" exact>
                    <Accommodations />
                  </ProtectedRoute>
                  <ProtectedRoute path="/" exact>
                    <Redirect to="/accommodations" />
                  </ProtectedRoute>
                  <ProtectedRoute>
                    <Error404 />
                  </ProtectedRoute>
                </Switch>
              </RouterListener>
            </Router>
          </ConfigProvider>
        </ThemeProvider>
      </Provider>
    </CacheBuster>
  );
};

export default App;
