import { Formik } from 'formik';
import I18n from 'i18n';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import * as actions from '../../../actions';
import { getInitialEditDetailsFormValues } from '../../../selectors';
import RenderForm from './RenderForm';

const schema = yup.object().shape({
  name: yup.string().required(I18n.t('common:form.errors.required')),
  companyName: yup.string().nullable(),
  vatId: yup.string().nullable(),
  phone: yup.string().required(I18n.t('common:form.errors.required')),
  addressAttributes: yup.object().shape({
    street: yup.string().required(I18n.t('common:form.errors.required')),
    city: yup.string().required(I18n.t('common:form.errors.required')),
    zip: yup.string().required(I18n.t('common:form.errors.required')),
    country: yup.string().required(I18n.t('common:form.errors.required')),
  }),
});

const EditDetailsForm = () => {
  const dispatch = useDispatch();
  const initialValues = useSelector(getInitialEditDetailsFormValues);

  const handleSubmit = useCallback(
    (values, form) => {
      dispatch(actions.updateDetails(values, form));
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
      validateOnBlur
      onSubmit={handleSubmit}
    >
      {formProps => <RenderForm {...formProps} />}
    </Formik>
  );
};

export default memo(EditDetailsForm);
