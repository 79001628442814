import 'styled-components/macro';

import { HourglassOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { memo } from 'react';
import styled from 'styled-components';
import theme from 'theme';

const colorsMap = {
  included: theme.colors.green5,
  pending: theme.colors.grey6,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const SettlementInclusionStatus = ({ status, tooltip }) => {
  if (!['included', 'pending'].includes(status)) {
    return null;
  }

  return (
    <Tooltip title={tooltip}>
      <Container>
        <HourglassOutlined
          css={`
            color: ${colorsMap[status]};
            font-size: 1.7rem;
          `}
        />
      </Container>
    </Tooltip>
  );
};

export default memo(SettlementInclusionStatus);
