import { intersection, reverse, sortBy, uniq } from 'lodash';
import { createSelector } from 'reselect';

const getBookingsMap = state => state.bookings.bookings;
const getStatusOptions = state => state.bookings.options.statuses;
export const getIsCollectionFetched = state => state.bookings.isCollectionFetched;

export const getBookings = createSelector(getBookingsMap, bookingsMap => {
  const collection = Object.values(bookingsMap).map(booking => ({ ...booking.attributes, key: booking.id }));

  return reverse(sortBy(collection, item => item.arrival));
});

export const getAccommodationFilterOptions = createSelector(getBookings, bookings => {
  return uniq(bookings.map(booking => booking.accommodationName)).map(accommodationName => ({
    text: accommodationName,
    value: accommodationName,
  }));
});

export const getStatusFilterOptions = createSelector([getBookings, getStatusOptions], (bookings, statusOptions) => {
  return intersection(statusOptions, uniq(bookings.map(booking => booking.status)));
});
