import { isThisYear } from 'date-fns';
import produce from 'immer';
import { handleActions } from 'redux-actions';
import { optimistic } from 'redux-optimistic-ui';

import * as actions from './actions';

export const initialState = {
  isCollectionFetched: false,
  nonChargeableNights: {
    total: null,
    remaining: null,
  },
  selectedAccommodationId: null,
  myBookings: {},
  changingStay: {
    bookingId: null,
    selectedAccommodationId: null,
  },
};

const reducer = handleActions(
  {
    [actions.setIsCollectionFetched](state) {
      return produce(state, newState => {
        newState.isCollectionFetched = true;
      });
    },
    [actions.setStays](state, { payload }) {
      return produce(state, newState => {
        newState.myBookings = payload;
      });
    },
    [actions.setNonChargeableNights](state, { payload }) {
      return produce(state, newState => {
        newState.nonChargeableNights = payload;
      });
    },
    [actions.setNote](state, { payload }) {
      return produce(state, newState => {
        newState.myBookings[payload.id].attributes.notes = payload.content;
      });
    },
    [actions.setSelectedAccommodationId](state, { payload }) {
      return produce(state, newState => {
        newState.selectedAccommodationId = payload;
      });
    },
    [actions.setChangingStayBookingId](state, { payload }) {
      return produce(state, newState => {
        newState.changingStay.bookingId = payload;
      });
    },
    [actions.resetChangingStayBookingId](state) {
      return produce(state, newState => {
        newState.changingStay.bookingId = null;
      });
    },
    [actions.setChangingStaySelectedAccommodationId](state, { payload }) {
      return produce(state, newState => {
        newState.changingStay.selectedAccommodationId = payload;
      });
    },
    [actions.cancelStay](state, { payload }) {
      return produce(state, newState => {
        newState.myBookings[payload.id].attributes.status = 'canceled';
      });
    },
  },
  initialState
);

export default optimistic(reducer);
