export default {
  sansSerif: "'Open Sans', sans-serif",
  sizes: {
    headerHeight: '10rem',
  },
  colors: {
    white: '#FFF',
    tableBorderColor: '#f4f4f4',
    darkHalf: '#494949',
    purple: '#d897eb',
    alabaster: '#f8f8f8',
    doveGray: '#666',
    dustyGray: '#999',
    yellow1: '#feffe6',
    yellow2: '#ffffb8',
    yellow4: '#ffdd76',
    yellow6: '#ffbf00',
    gold3: '#ffe58f',
    gold4: '#ffd666',
    gold5: '#ffc53d',
    gold6: '#faad14',
    gold7: '#d48806',
    orange1: '#fff7e6',
    orange2: '#ffe7ba',
    orange3: '#ffd591',
    orange4: '#ffc069',
    orange5: '#ffa940',
    orange6: '#fa8c16',
    blue1: '#ecf6fd',
    blue2: '#d2eafb',
    blue3: '#add8f7',
    blue4: '#7ec2f3',
    blue5: '#49a9ee',
    blue6: '#108ee9',
    blue7: '#0e77ca',
    purple1: '#f9f0ff',
    purple2: '#efdbff',
    purple3: '#d3adf7',
    purple4: '#b37feb',
    purple5: '#9254de',
    purple6: '#722ed1',
    purple7: '#531dab',
    red2: '#ffccc7',
    red3: '#ffa39e',
    red4: '#ff7875',
    red5: '#f46e65',
    red6: '#f04134',
    red7: '#d73435',
    green5: '#3dbd7d',
    green6: '#00a854',
    green7: '#00924c',
    grey2: '#f7f7f7',
    grey3: '#f5f5f5',
    grey4: '#e9e9e9',
    grey5: '#d9d9d9',
    grey6: '#bfbfbf',
    grey7: '#919191',
    grey10: '#222222',
    cyan1: '#e6fffb',
    cyan2: '#b5f5ec',
    cyan3: '#87e8de',
    cyan4: '#5cdbd3',
    pippin: '#FFE7E7',
    fairPink: '#FFF1F1',
    geekblue1: '#f0f5ff',
    geekblue2: '#d6e4ff',
  },
  transitions: {
    transitionEaseIn: 'all 0.15s ease-out',
    transitionEaseOut: 'all 0.15s ease-out',
    transitionEaseInOut: 'all 0.15s ease-in-out',
    easeIn: 'ease-in',
  },
  breakpoints: {
    xs: '@media (max-width: 575px)',
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
    xl: '@media (min-width: 1200px)',
    xxl: '@media (min-width: 1600px)',
  },
};
