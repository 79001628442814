import 'styled-components/macro';

import { Radio } from 'antd';
import { format } from 'date-fns';
import pl from 'date-fns/locale/pl';
import { capitalize } from 'lodash';
import accommodations from 'modules/accommodations';
import app from 'modules/app';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { capitalizeFirstWord } from 'utils/helpers';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

const localeMap = {
  pl,
};

const Periods = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation(['common']);

  const dispatch = useDispatch();
  const isAccommodationsCollectionFetched = useSelector(accommodations.selectors.getIsCollectionFetched, shallowEqual);
  const settlementEffectivityPeriods = useSelector(selectors.getSettlementEffectivityPeriods);
  const selectedPeriodValue = useSelector(selectors.getSelectedPeriodValue);

  const [breakpoint] = app.hooks.useBreakpoints();
  const isAtLeastLgBreakpoint = ['lg', 'xl', 'xxl'].includes(breakpoint);

  const handleChange = useCallback(
    event => {
      const { start, end } = event.target;

      dispatch(actions.addFetchSettlementParams({ start, end }));
    },
    [dispatch]
  );

  return (
    <Radio.Group
      buttonStyle="solid"
      disabled={!isAccommodationsCollectionFetched}
      value={selectedPeriodValue}
      onChange={handleChange}
    >
      {settlementEffectivityPeriods.map(period => {
        const { start, end, type, current } = period;

        const periodName = {
          month: capitalize(format(new Date(period.start), 'LLLL yyyy', { locale: localeMap[language] })),
          quarter: capitalizeFirstWord(format(new Date(period.start), 'qqqq yyyy', { locale: localeMap[language] })),
        }[type];

        const addition = current ? ` (${t('modules.settlement.current')})` : '';
        const periodNameFormatted = `${periodName}${addition}`;

        return isAtLeastLgBreakpoint ? (
          <Radio.Button key={start} end={end} start={start} value={`${start}-${end}`}>
            {periodNameFormatted}
          </Radio.Button>
        ) : (
          <Radio
            key={start}
            css={`
              display: block;
              height: 30px;
              line-height: 30px;
            `}
            end={end}
            start={start}
            value={`${start}-${end}`}
          >
            {periodNameFormatted}
          </Radio>
        );
      })}
    </Radio.Group>
  );
};

export default memo(Periods);
