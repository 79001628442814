import { Badge } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const statusesMap = {
  confirmed: 'success',
  canceled: 'error',
  completed: 'default',
};

export const renderStatusOption = status => <StatusOption status={status} />;

export const StatusOption = ({ status }) => {
  const { t } = useTranslation(['common']);

  return (
    <Badge
      key={status}
      status={statusesMap[status]}
      style={{ width: 'max-content' }}
      text={t(`modules.stays.statuses.${status}`)}
    />
  );
};
