import styled from 'styled-components';

export const FooterContainer = styled.div`
  padding: 0.5rem 0;
`;

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
  },
};

export const noLabelWrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: 12, offset: 6 },
  xl: { span: 12, offset: 6 },
  xxl: { span: 12, offset: 6 },
};
