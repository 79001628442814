import { Form } from 'antd';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../actions';
import * as hooks from '../hooks';
import { getChangingStayBookingId } from './selectors';

export const useForm = (isVisible, closeModal, resetPreview) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const bookingId = useSelector(getChangingStayBookingId, shallowEqual);

  const reset = React.useCallback(() => {
    form.resetFields();
    resetPreview();
  }, []);

  React.useEffect(() => {
    reset();
  }, [isVisible]);

  const valuesToPayload = React.useCallback(
    values => ({
      accommodationId: values.accommodationId,
      guestCount: values.guestCount,
      arrival: values.dates[0].format('YYYY-MM-DD'),
      departure: values.dates[1].format('YYYY-MM-DD'),
      currentBookingId: bookingId,
      id: bookingId,
    }),
    [bookingId]
  );

  const handleSubmit = React.useCallback(
    values => {
      setIsSubmitting(true);
      dispatch(
        actions.changeStay({
          values: valuesToPayload(values),
          onSuccess: closeModal,
          onEnd: () => {
            setIsSubmitting(false);
          },
        })
      );
    },
    [valuesToPayload]
  );

  return [form, valuesToPayload, handleSubmit, isSubmitting, reset];
};

export const useFormObserver = (handleFormChange, valuesToPayload) => {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = React.useState({});

  const onValuesChange = React.useCallback((_changedValues, allValues) => {
    setFormValues(allValues);
  }, []);

  React.useEffect(() => {
    dispatch(actions.setChangingStaySelectedAccommodationId(formValues.accommodationId));
  }, [formValues.accommodationId]);

  React.useEffect(() => {
    if (formValues.accommodationId && formValues.dates && formValues.dates.length === 2 && formValues.guestCount) {
      handleFormChange(valuesToPayload(formValues));
    }
  }, [formValues, valuesToPayload]);

  return [onValuesChange];
};

export const useModal = () => {
  const dispatch = useDispatch();
  const bookingId = useSelector(getChangingStayBookingId, shallowEqual);
  const [isVisible, width, handleModalOpen, handleModalClose] = hooks.useModal();

  React.useEffect(() => {
    !!bookingId ? handleModalOpen() : handleModalClose();
  }, [bookingId]);

  const closeModal = React.useCallback(() => {
    handleModalClose();
    dispatch(actions.resetChangingStayBookingId());
  }, []);

  return [isVisible, closeModal, width];
};
