import { differenceInCalendarDays, format, isAfter, isSameDay, startOfDay } from 'date-fns';
import { reverse, sortBy, sortedUniq, uniq } from 'lodash';
import accommodations from 'modules/accommodations';
import { createSelector } from 'reselect';

const getStaysMap = state => state.stays.current.myBookings;
export const getIsCollectionFetched = state => state.stays.current.isCollectionFetched;
export const getNonChargeableNights = state => state.stays.current.nonChargeableNights;
const getSelectedAccommodationId = state => state.stays.current.selectedAccommodationId;

export const getStays = createSelector(getStaysMap, staysMap => {
  const collection = Object.values(staysMap).map(stay => ({
    ...stay.attributes,
    nightCount: differenceInCalendarDays(new Date(stay.attributes.departure), new Date(stay.attributes.arrival)),
    key: stay.id,
  }));

  return reverse(sortBy(collection, item => item.arrival));
});

export const getConfirmedStays = createSelector(getStays, stays => {
  return stays.filter(stay => stay.status === 'confirmed');
});

export const getCompletedStays = createSelector(getStays, stays => {
  return stays.filter(stay => stay.status === 'completed');
});

export const getCanceledStays = createSelector(getStays, stays => {
  return stays.filter(stay => stay.status === 'canceled');
});

export const getAccommodationFilterOptions = createSelector(getStays, stays => {
  return uniq(stays.map(stay => stay.accommodationName)).map(accommodationName => ({
    text: accommodationName,
    value: accommodationName,
  }));
});

const getStaysInFuture = createSelector(getStays, stays =>
  stays
    .filter(stay => stay.status === 'confirmed')
    .filter(stay => isSameDay(new Date(stay.arrival), new Date()) || isAfter(new Date(stay.arrival), new Date()))
);

export const getStaysInFutureCount = createSelector(getStaysInFuture, stays => stays.length);

export const getAccommodationsSelectOptions = createSelector(
  accommodations.selectors.getAccommodationsMap,
  accommodationsMap => {
    return Object.values(accommodationsMap).map(({ attributes }) => ({
      value: attributes.id,
      disabled: !attributes.agreementOngoing,
      title: `${attributes.name} / ${attributes.propertyName} / ${attributes.locationName}`,
    }));
  }
);

export const getSelectedAccommodation = createSelector(
  [accommodations.selectors.getAccommodationsMap, getSelectedAccommodationId],
  (accommodationsMap, id) => {
    if (!id) {
      return null;
    }

    const accommodation = accommodationsMap[id];

    return {
      ...accommodation,
      attributes: {
        ...accommodation.attributes,
        availableDates: accommodation.attributes.availableDates.map(date => new Date(date)),
      },
    };
  }
);

export const getMaxNumAdults = createSelector(getSelectedAccommodation, accommodation => {
  if (!accommodation) {
    return null;
  }

  return accommodation.attributes.maxNumAdults;
});

export const getSelectedAccommodationAvailableDates = createSelector([getSelectedAccommodation], accommodation => {
  if (!accommodation) {
    return [];
  }

  const availableDates = accommodation.attributes.availableDates || [];
  const minimumDate = startOfDay(new Date());

  return sortedUniq(
    sortBy(
      availableDates.filter(date => isSameDay(date, minimumDate) || isAfter(date, minimumDate)),
      date => date
    ).map(date => format(date, 'yyyy-MM-dd'))
  );
});
