import { Alert, Button, Form, Input, InputNumber, Select, Skeleton, Spin, Statistic } from 'antd';
import { Field } from 'formik';
import accommodations from 'modules/accommodations';
import app from 'modules/app';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFieldHasFeedback, getFieldHelp, getFieldValidateStatus } from 'utils/helpers';
import { formatMoney } from 'utils/helpers';

import * as actions from '../../../../actions';
import { getAccommodationsSelectOptions, getSelectedAccommodationAvailableDates } from '../../../../selectors';
import * as hooks from '../../hooks';

const DateRangePicker = app.components.DateRangePicker;
const FormikEffect = app.components.FormikEffect;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
  },
};

const noLabelWrapperCol = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: 12, offset: 6 },
  xl: { span: 12, offset: 6 },
  xxl: { span: 12, offset: 6 },
};

const RenderForm = props => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    values,
    setFieldValue,
    setFieldTouched,
    schema,
    setSelectedAccommodationId,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);

  const accommodationSelectOptions = useSelector(getAccommodationsSelectOptions);
  const isAccommodationsCollectionFetched = useSelector(accommodations.selectors.getIsCollectionFetched, shallowEqual);
  const availableDates = useSelector(getSelectedAccommodationAvailableDates);

  const [isStayPossible, status, serviceCharge, isCheckingStayPossibility, handleFormChange] = hooks.useStayPreview();

  const getPopupContainer = useCallback(() => {
    return document.getElementsByClassName('accommodation-select')[0];
  }, []);

  const handleAccommodationChange = useCallback(
    ({ form, field }) =>
      value => {
        form.setFieldValue(field.name, value);
        app.helpers.setTouched(form, field.name);
        dispatch(actions.setSelectedAccommodationId(value));
      },
    [setSelectedAccommodationId, setFieldValue]
  );

  const handleDateRangeChange = useCallback(
    dates => {
      const [start, end] = dates;

      setFieldValue('arrival', start.format('YYYY-MM-DD'));
      setFieldValue('departure', end.format('YYYY-MM-DD'));
    },
    [setFieldValue]
  );

  const handleGuestCountChange = useCallback(
    ({ form, field }) =>
      value => {
        form.setFieldValue(field.name, value);
        app.helpers.setTouched(form, field.name);
      },
    []
  );

  return (
    <Skeleton loading={!isAccommodationsCollectionFetched}>
      <Spin spinning={isCheckingStayPossibility}>
        <Form {...formItemLayout} onFinish={handleSubmit}>
          <FormikEffect
            debounceTime={300}
            keysToListen={['accommodationId', 'arrival', 'departure', 'guestCount']}
            schema={schema}
            value={values}
            onChange={handleFormChange}
          />
          <Field component="input" name="id" type="hidden" />
          <Field component="input" name="stayId" type="hidden" />
          <Field name="accommodationId">
            {fieldProps => (
              <Form.Item
                hasFeedback={getFieldHasFeedback(fieldProps)}
                help={getFieldHelp(fieldProps)}
                label={t('form.labels.accommodation')}
                validateStatus={getFieldValidateStatus(fieldProps)}
                required
              >
                <Select
                  {...fieldProps.field}
                  className="accommodation-select"
                  getPopupContainer={getPopupContainer}
                  style={{ width: '100%' }}
                  onChange={handleAccommodationChange(fieldProps)}
                >
                  {accommodationSelectOptions.map(({ title, value, disabled }) => (
                    <Select.Option key={value} disabled={disabled} value={value}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Field>
          <Form.Item label={t('form.labels.dates')} required>
            <DateRangePicker
              availableDates={availableDates}
              disabled={!availableDates.length}
              endValue={values.departure}
              footerMessage={t('modules.stays.messages.chooseDatesHelp')}
              placeholder={[t('form.placeholders.arrival'), t('form.placeholders.departure')]}
              startValue={values.arrival}
              onChange={handleDateRangeChange}
            />
          </Form.Item>
          <Field name="guestCount">
            {fieldProps => {
              const { field } = fieldProps;

              return (
                <Form.Item
                  hasFeedback={getFieldHasFeedback(fieldProps)}
                  help={getFieldHelp(fieldProps)}
                  label={t('form.labels.guestCount')}
                  validateStatus={getFieldValidateStatus(fieldProps)}
                  required
                >
                  <InputNumber
                    {...field}
                    data-cy="guest-count"
                    disabled={!availableDates.length}
                    max={10}
                    min={0}
                    onChange={handleGuestCountChange(fieldProps)}
                  />
                </Form.Item>
              );
            }}
          </Field>
          <Field name="note">
            {fieldProps => (
              <Form.Item
                hasFeedback={getFieldHasFeedback(fieldProps)}
                help={getFieldHelp(fieldProps)}
                label={t('form.labels.notes')}
                validateStatus={getFieldValidateStatus(fieldProps)}
              >
                <Input.TextArea rows={4} {...fieldProps.field} />
              </Form.Item>
            )}
          </Field>
          {Object.keys(status).length ? (
            <Form.Item wrapperCol={noLabelWrapperCol}>
              <Alert data-cy="stay-status-message" message={status.message} type={status.alertType} showIcon />
            </Form.Item>
          ) : null}
          {serviceCharge ? (
            <Form.Item wrapperCol={noLabelWrapperCol}>
              <Statistic title={t('modules.stay.serviceCharge')} value={formatMoney(serviceCharge)} />
            </Form.Item>
          ) : null}
          <Form.Item wrapperCol={noLabelWrapperCol}>
            <Button disabled={!isStayPossible || !isValid} htmlType="submit" loading={isSubmitting} type="primary">
              {t('actions.plan')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Skeleton>
  );
};

export default memo(RenderForm);
