import { Table as AntTable } from 'antd';
import { format } from 'date-fns';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { formatMoney } from 'utils/helpers';

import * as selectors from '../../selectors';
import Amount from './Amount';

const Table = ({ loading }) => {
  const { t } = useTranslation(['common']);
  const operations = useSelector(selectors.getOperations, shallowEqual);
  const summary = useSelector(selectors.getSummary, shallowEqual);

  const renderMoneyOrNotApplicable = useCallback(
    (text, record) => (text ? formatMoney(text, record.currency) : t('modules.settlement.table.notApplicable')),
    [t]
  );

  const renderTextOrNotApplicable = useCallback(text => text || t('modules.settlement.table.notApplicable'), [t]);

  const columns = [
    {
      title: t('modules.settlement.table.effectiveAt'),
      width: 100,
      align: 'center',
      dataIndex: 'effectiveAt',
      render: text => format(new Date(text), 'dd-MM-yyyy'),
      key: 'effectiveAt',
    },
    {
      title: t('modules.settlement.table.title'),
      dataIndex: 'title',
      key: 'title',
      width: 300,
    },
    {
      title: t('modules.settlement.table.accommodationName'),
      dataIndex: 'accommodationName',
      key: 'accommodationName',
      width: 170,
    },
    {
      title: t('modules.settlement.table.booking'),
      children: [
        {
          title: t('modules.settlement.table.bookingValue'),
          dataIndex: 'bookingValue',
          key: 'bookingValue',
          width: 140,
          align: 'right',
          render: renderMoneyOrNotApplicable,
        },
        {
          title: t('modules.settlement.table.bookingValueNoVat'),
          dataIndex: 'bookingValueNoVat',
          key: 'bookingValueNoVat',
          width: 140,
          align: 'right',
          render: renderMoneyOrNotApplicable,
        },
      ],
    },
    {
      title: t('modules.settlement.table.grossExpenditure'),
      dataIndex: 'grossExpenditure',
      key: 'grossExpenditure',
      align: 'right',
      render: renderMoneyOrNotApplicable,
      width: 150,
    },
    {
      title: t('modules.settlement.table.commissionPercentage'),
      dataIndex: 'commissionPercentage',
      key: 'commissionPercentage',
      render: renderTextOrNotApplicable,
      align: 'right',
      width: 80,
    },
    {
      title: t('modules.settlement.table.netDueForOwner'),
      dataIndex: 'netDueForOwner',
      key: 'netDueForOwner',
      align: 'right',
      width: 170,
      fixed: 'right',
      render: (text, { flowDirection, currency }) => (
        <Amount currency={currency} direction={flowDirection} value={text} />
      ),
    },
  ];

  return (
    <AntTable
      columns={columns}
      dataSource={operations}
      loading={loading}
      pagination={false}
      scroll={{ x: 1200 }}
      size="small"
      bordered
    />
  );
};

export default memo(Table);
