import { message } from 'antd';
import I18n from 'i18n';
import normalize from 'json-api-normalizer';
import accommodations from 'modules/accommodations';
import { createAction } from 'redux-actions';
import api from 'services/api';

import * as types from './actionTypes';
import * as selectors from './selectors';

export const addFetchSettlementParams = createAction(types.ADD_FETCH_SETTLEMENT_PARAMS);
export const setIsSettlementComplete = createAction(types.SET_IS_SETTLEMENT_COMPLETE);
export const setIsSettlementFetched = createAction(types.SET_IS_SETTLEMENT_FETCHED);
export const setInvoiceDetails = createAction(types.SET_INVOICE_DETAILS);
export const setOperations = createAction(types.SET_OPERATIONS);
export const setOptions = createAction(types.SET_OPTIONS);
export const setSelectedAccommodationId = createAction(types.SET_SELECTED_ACCOMMODATION_ID);
export const setSummary = createAction(types.SET_SUMMARY);

export const fetchAccommodations = () => dispatch => {
  dispatch(accommodations.actions.setIsCollectionFetched(false));

  api
    .get('/accommodations')
    .then(({ data }) => {
      if (data.data.length) {
        const normalizedData = normalize(data);

        dispatch(accommodations.actions.setAccommodations(normalizedData.accommodation));
        dispatch(setSelectedAccommodationId(0));
        dispatch(setAccommodationInitialPeriodDates());
      }

      dispatch(accommodations.actions.setIsCollectionFetched(true));
    })
    .catch(error => {
      console.error(error);
      message.error(I18n.t('common:modules.accommodations.messages.fetchAccommodations.failure'));
    });
};

export const fetchSettlement = () => (dispatch, getState) => {
  dispatch(setIsSettlementFetched(false));
  dispatch(setIsSettlementComplete(true));

  const state = getState();
  const params = selectors.getFetchSettlementParams(state);

  return api
    .get('/settlement', { params: params })
    .then(({ data }) => {
      if (data.data.length) {
        const normalizedData = normalize(data);
        dispatch(setOperations(normalizedData.operation));
        dispatch(setSummary(data.meta.summary));
        dispatch(setInvoiceDetails(data.meta.invoiceDetails));
      } else {
        dispatch(setOperations({}));
        dispatch(setSummary(undefined));
      }

      dispatch(setIsSettlementComplete(data.meta.settlementComplete));
      dispatch(setIsSettlementFetched(true));
    })
    .catch(error => {
      console.error(error);
      message.error(I18n.t('common:modules.settlement.messages.fetchSettlement.failure'));
    });
};

export const setAccommodationInitialPeriodDates = () => (dispatch, getState) => {
  const state = getState();
  const selectedAccommodationInitialPeriod = selectors.getAccommodationInitialPeriod(state);
  const selectedPeriod = selectors.getSelectedPeriodValue(state);
  const { start, end } = selectedAccommodationInitialPeriod;

  if (!selectedPeriod) {
    dispatch(addFetchSettlementParams({ start, end }));
  }
};
