import 'styled-components/macro';

import { Layout as AntLayout } from 'antd';
import app from 'modules/app';
import auth from 'modules/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Logo from '../Logo';
import Header from './Header';
import Navigation from './Navigation';
import PageHeader from './PageHeader';
import * as Styled from './styled';

const { Sider } = AntLayout;

const SIDER_WIDTH = 240;
const SIDER_COLLAPSED_WIDTH = 60;

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [isCollapsed, setIsCollapsed] = useState(localStorage.getItem('siderCollapsed') || false);
  const [breakpoint] = app.hooks.useBreakpoints();
  const isAtLeastLgBreakpoint = ['xl', 'xxl'].includes(breakpoint);

  useEffect(() => {
    if (!isAtLeastLgBreakpoint) {
      setIsCollapsed(true);
    }
  }, [setIsCollapsed, isAtLeastLgBreakpoint]);

  const logout = useCallback(() => {
    dispatch(auth.actions.logout());
  }, [dispatch]);

  const handleCollapse = useCallback(
    collapsed => {
      localStorage.setItem('siderCollapsed', collapsed);
      setIsCollapsed(collapsed);
    },
    [setIsCollapsed]
  );

  return (
    <AntLayout
      css={`
        min-height: 100vh;
      `}
    >
      <Sider
        collapsed={isCollapsed}
        collapsedWidth={SIDER_COLLAPSED_WIDTH}
        css={`
          overflow: auto;
          height: 100vh;
          position: fixed;
          left: 0;

          .ant-menu {
            width: ${isCollapsed ? SIDER_COLLAPSED_WIDTH : SIDER_WIDTH}px;
          }

          .ant-menu-item,
          .ant-menu-submenu .ant-menu-submenu-title {
            padding: 0 ${isCollapsed ? '22px' : '32px'} !important;
          }
        `}
        width={SIDER_WIDTH}
        collapsible
        onCollapse={handleCollapse}
      >
        <Styled.BrandContainer>
          <Logo collapsed={isCollapsed} />
        </Styled.BrandContainer>
        <Navigation />
      </Sider>
      <AntLayout
        css={`
          margin-left: ${isCollapsed ? SIDER_COLLAPSED_WIDTH : SIDER_WIDTH}px;
          transition: margin-left 0.15s ease-in-out;
        `}
      >
        <Header logout={logout} />
        {children}
        <AntLayout.Footer
          css={`
            text-align: center;
          `}
        >
          Revenuo ©2019 Created by Sun Seasons 24
        </AntLayout.Footer>
      </AntLayout>
    </AntLayout>
  );
};

Layout.ContentInner = Styled.ContentInner;
Layout.PageHeader = PageHeader;

export default Layout;
