import React, { memo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { formatMoney } from 'utils/helpers';

const getColor = direction => {
  return {
    in: theme.colors.green5,
    out: theme.colors.red6,
  }[direction];
};

const signsMap = {
  in: '+',
  out: '',
};

const Container = styled.span`
  color: ${props => getColor(props.direction)};
  font-weight: 600;
  width: auto;
  white-space: nowrap;
`;

const Amount = ({ value, direction, currency, noSign = false }) => (
  <Container direction={direction}>
    {noSign ? null : signsMap[direction]}
    {formatMoney(value, currency)}&nbsp;&nbsp;
  </Container>
);

export default memo(Amount);
