import 'styled-components/macro';

import { PageHeader as AntPageHeader } from 'antd';
import I18n from 'i18n';
import auth from 'modules/auth';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import * as Styled from './styled';

const getBreadrumbsNames = () => ({
  '/account-settings': I18n.t('common:menuNames.accountSettings'),
  '/bookings-list': I18n.t('common:menuNames.bookingsList'),
  '/your-stays': I18n.t('common:menuNames.yourStays'),
  '/settlement': I18n.t('common:menuNames.settlement'),
  '/accommodations': I18n.t('common:menuNames.accommodations'),
  '/': 'Home',
});

const getExtraBreadcrumbItems = pathname => {
  const pathSnippets = pathname.split('/').filter(i => i);

  return pathSnippets.map((_snippet, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const breadcrumbNames = getBreadrumbsNames();
    const name = breadcrumbNames[url] || breadcrumbNames[pathname];

    return { path: url, breadcrumbName: name };
  });
};

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? <span>{route.breadcrumbName}</span> : <Link to={paths.join('/')}>{route.breadcrumbName}</Link>;
}

const PageHeader = ({ children, ...rest }) => {
  const backPath = useSelector(auth.selectors.getRedirectTo);
  const history = useHistory();
  const location = useLocation();

  const routes = [
    {
      path: '/',
      breadcrumbName: 'Home',
    },
    ...getExtraBreadcrumbItems(location.pathname),
  ];

  const handleBack = useCallback(() => {
    history.push(backPath);
  }, [history, backPath]);

  return (
    <Styled.PageHeaderContainer>
      <AntPageHeader
        css={`
          padding: 1.6rem 2.4rem 2rem;
        `}
        ghost={false}
        onBack={handleBack}
        {...rest}
        breadcrumb={{ routes, itemRender }}
      >
        {children}
      </AntPageHeader>
    </Styled.PageHeaderContainer>
  );
};

export default memo(PageHeader);
