import blank from 'images/blank.jpg';
import { createSelector } from 'reselect';

const getIds = state => state.accommodations.ids;
export const getAccommodationsMap = state => state.accommodations.byId;
export const getIsCollectionFetched = state => state.accommodations.isCollectionFetched;
export const makeGetIsCollectionFetched = () => state => state.accommodations.isCollectionFetched;

const computeAccommodation = accommodation => ({
  ...accommodation.attributes,
  image: accommodation.attributes.image || blank,
});

export const getAccommodations = createSelector([getAccommodationsMap, getIds], (accommodationsMap, ids) =>
  ids.map(id => computeAccommodation(accommodationsMap[id]))
);

export const getAccommodationsWithOngoingAgreement = createSelector(
  [getAccommodationsMap, getIds],
  (accommodationsMap, ids) =>
    ids.map(id => computeAccommodation(accommodationsMap[id])).filter(accommodation => accommodation.agreementOngoing)
);
