import uuid from '_lib/uuid';
import I18n from 'i18n';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import * as yup from 'yup';

import { useBreakpoints } from '../../../app/hooks';
import * as actions from '../../actions';
import { getMaxNumAdults } from '../../selectors';

const modalWidths = {
  xs: '90vw',
  sm: '90vw',
  md: '90vw',
  lg: '900px',
  xl: '900px',
  xxl: '900px',
};

export const useModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [breakpoint] = useBreakpoints();

  const handleModalOpen = useCallback(() => {
    setIsModalVisible(true);
  }, [setIsModalVisible]);

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  const width = modalWidths[breakpoint];

  return [isModalVisible, width, handleModalOpen, handleModalClose];
};

const previewInitialState = { possible: false, message: null };
const serviceChargeInitialState = null;

export const useStayPreview = () => {
  const { t } = useTranslation(['common']);
  const [preview, setPreview] = useState(previewInitialState);
  const [serviceCharge, setServiceCharge] = useState(null);
  const [isCheckingStayPossibility, setIsCheckingStayPossibility] = useState(false);

  const reset = useCallback(() => {
    setPreview(previewInitialState);
    setServiceCharge(null);
    setIsCheckingStayPossibility(false);
  }, []);

  const statuses = useMemo(
    () => ({
      possible: { alertType: 'success', message: t('modules.stay.messages.preview.possible') },
      dates_unavailable: { alertType: 'error', message: t('modules.stay.messages.preview.datesUnavailable') },
      min_nights_unfulfilled: {
        alertType: 'error',
        message: t('modules.stay.messages.preview.minNightsUnfulfilled'),
      },
      must_arrive_before_departure: {
        alertType: 'error',
        message: t('modules.stay.messages.preview.mustArriveBeforeDeparture'),
      },
      max_stay_nights_exceeded: {
        alertType: 'warning',
        message: t('modules.stay.messages.preview.maxStayNightsExceeded'),
      },
    }),
    [t]
  );

  const status = preview.message ? statuses[preview.message] : {};

  const handleFormChange = useCallback(
    values => {
      setIsCheckingStayPossibility(true);

      api
        .get('/stays/check-possibility', { params: values })
        .then(({ data }) => {
          setPreview(data);

          if (data.possible) {
            api
              .get('/stays/calculate-cost', { params: values })
              .then(({ data }) => {
                setServiceCharge(data.serviceCharge);
              })
              .catch(() => {})
              .finally(() => {
                setIsCheckingStayPossibility(false);
              });
          } else {
            setIsCheckingStayPossibility(false);
            setServiceCharge(null);
          }
        })
        .catch(() => {});
    },
    [setServiceCharge, setPreview, setIsCheckingStayPossibility]
  );

  return [preview.possible, status, serviceCharge, isCheckingStayPossibility, handleFormChange, reset];
};

const getSchema = maxNumAdults =>
  yup.object().shape({
    accommodationId: yup.number().required(I18n.t('common:form.errors.required')),
    arrival: yup.date().required(I18n.t('common:form.errors.required')),
    departure: yup.date().required(I18n.t('common:form.errors.required')),
    guestCount: yup
      .number()
      .min(1, I18n.t('common:form.errors.minGuestCount', { min: 1 }))
      .max(maxNumAdults, I18n.t('common:form.errors.maxGuestCount', { max: maxNumAdults }))
      .required(I18n.t('common:form.errors.required')),
    note: yup.string().nullable(),
  });

export const useForm = closeModal => {
  const dispatch = useDispatch();
  const maxNumAdults = useSelector(getMaxNumAdults);
  const schema = getSchema(maxNumAdults);

  const handleSubmit = useCallback(
    (values, form) => {
      dispatch(
        actions.placeBooking({
          values,
          form,
          onSuccess: closeModal,
        })
      );
    },
    [dispatch]
  );

  return [handleSubmit, schema];
};
