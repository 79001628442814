import 'styled-components/macro';

import { Card, Col, Row } from 'antd';
import app from 'modules/app';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import { getIsCollectionFetched } from '../../selectors';
import Table from './Table';

const Layout = app.components.Layout;

const List = () => {
  const { t } = useTranslation(['common']);

  const isCollectionFetched = useSelector(getIsCollectionFetched);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isCollectionFetched) {
      dispatch(actions.fetchBookings());
    }
  }, [isCollectionFetched, dispatch]);

  return (
    <>
      <Helmet>
        <title>{`${t('appName')} - ${t('modules.bookingsList.title')}`}</title>
      </Helmet>
      <Layout.PageHeader title={t('modules.bookingsList.title')} />
      <Layout.ContentInner>
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              <Table loading={!isCollectionFetched} />
            </Card>
          </Col>
        </Row>
      </Layout.ContentInner>
    </>
  );
};

export default memo(List);
