import 'styled-components/macro';

import { Card, Col, Row } from 'antd';
import app from 'modules/app';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import { getIsAccountFetched } from '../../selectors';
import ChangePasswordForm from './ChangePasswordForm';
import EditDetailsForm from './EditDetailsForm';

const Layout = app.components.Layout;

const Settings = () => {
  const { t } = useTranslation(['common']);

  const isAccountFetched = useSelector(getIsAccountFetched);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAccountFetched) {
      dispatch(actions.fetchAccountDetails());
    }
  }, [isAccountFetched, dispatch]);

  return (
    <>
      <Helmet>
        <title>{`${t('common:appName')} - ${t('modules.accountSettings.title')}`}</title>
      </Helmet>
      <Layout.PageHeader title={t('modules.accountSettings.title')} />
      <Layout.ContentInner>
        <Row
          css={`
            margin-bottom: 1.6rem;
          `}
        >
          <Col span={24}>
            <Card
              bordered={false}
              loading={!isAccountFetched}
              title={t('modules.accountSettings.sections.editDetails')}
            >
              <EditDetailsForm />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card
              bordered={false}
              loading={!isAccountFetched}
              title={t('modules.accountSettings.sections.changePassword')}
            >
              <ChangePasswordForm />
            </Card>
          </Col>
        </Row>
      </Layout.ContentInner>
    </>
  );
};

export default memo(Settings);
