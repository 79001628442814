import { darken } from 'polished';
import styled from 'styled-components';

export const BrandContainer = styled.div`
  display: flex;
  height: 6rem;
  align-items: center;
  justify-content: space-around;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  > div {
    margin-left: -1rem;
    margin-bottom: -0.6rem;
  }
`;

export const ContentInner = styled.div`
  margin: 0 1.6rem 2.4rem;
  min-height: 80vh;
`;

export const menuCss = `
  height: 100%;
  border: none;
  margin: 0;
  background: none;
  color: ${props => props.theme.colors.white};
`;

export const subMenuCss = `
  top: 0;
  height: 100%;
  margin: 0;
  border: none !important;
  transition: ${props => props.theme.transitions.transitionEaseOut};

  &.ant-menu-submenu-active,
  &.ant-menu-submenu-open {
    margin: 0;
    background: ${props => darken(0.1, props.theme.colors.blue5)};
    border: none !important;

    .ant-menu-submenu-title {
      color: ${props => props.theme.colors.white};
    }
  }

  .ant-menu-submenu-title {
    display: flex;
    height: 100%;
    align-items: center;
    font-weight: 300;
  }
`;

export const PageHeaderContainer = styled.div`
  margin-bottom: 1.6rem;
`;
