import 'moment/locale/pl';

import uuid from '_lib/uuid';
import { Alert, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pl_PL';
import { noop } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useCalendarChanging, useCalendarOpening, useDisabledDateCalculation } from './hooks';
import * as Styled from './styled';

const { RangePicker } = DatePicker;

const CONTAINER_CLASSNAME = `date-range-picker-${uuid.v4()}`;

const DateRangePicker = ({
  availableDates,
  containerClassName,
  disabled,
  endValue,
  footerMessage,
  onChange,
  onOpenChange,
  placeholder,
  startValue,
}) => {
  const [handleCalendarChange, selectedDates, isCalendarTouched] = useCalendarChanging();
  const [isCalendarOpen, handleOpenChange] = useCalendarOpening(onOpenChange);
  const [calculateDisabledDate] = useDisabledDateCalculation(availableDates, selectedDates, isCalendarTouched);
  const value = startValue && endValue ? [moment(startValue), moment(endValue)] : [null, null];

  return (
    <div className={containerClassName} data-cy="date-range-picker">
      <RangePicker
        allowClear={false}
        disabled={disabled}
        disabledDate={calculateDisabledDate}
        format="DD-MM-YYYY"
        getCalendarContainer={() => document.getElementsByClassName(containerClassName)[0]}
        locale={locale}
        mode={['date', 'date']}
        open={isCalendarOpen}
        placeholder={placeholder}
        renderExtraFooter={() => {
          if (footerMessage) {
            return (
              <Styled.FooterContainer>
                <Alert message={footerMessage} type="info" showIcon />
              </Styled.FooterContainer>
            );
          }
          return null;
        }}
        value={value}
        onCalendarChange={handleCalendarChange}
        onChange={onChange}
        onOpenChange={handleOpenChange}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])),
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  endValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  footerMessage: PropTypes.string,
  onOpenChange: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.arrayOf(PropTypes.string),
  startValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateRangePicker.defaultProps = {
  availableDates: [],
  containerClassName: CONTAINER_CLASSNAME,
  disabled: false,
  endValue: null,
  footerMessage: null,
  placeholder: ['Start date', 'End date'],
  onChange: noop,
  onOpenChange: noop,
  startValue: null,
};

export default memo(DateRangePicker);
