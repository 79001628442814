import { message } from 'antd';
import I18n from 'i18n';
import normalize from 'json-api-normalizer';
import { createAction } from 'redux-actions';
import api from 'services/api';

import * as types from './actionTypes';

export const setAccountDetails = createAction(types.SET_ACCOUNT_DETAILS);
export const setIsAccountFetched = createAction(types.SET_IS_ACCOUNT_FETCHED);

export const fetchAccountDetails = () => dispatch => {
  api
    .get('/me')
    .then(({ data }) => {
      const normalizedData = normalize(data);
      const accountDetails = Object.values(normalizedData.owner)[0];

      dispatch(setAccountDetails(accountDetails));
      dispatch(setIsAccountFetched(true));
    })
    .catch(() => {
      message.error(I18n.t('common:modules.accountSettings.messages.fetchAccountDetails.failure'));
    });
};

export const changePassword = (values, form) => () => {
  api
    .put('/change-password', values)
    .then(() => {
      message.success(I18n.t('common:modules.accountSettings.messages.changePassword.success'));
      form.resetForm();
    })
    .catch(error => {
      if (error.response.status === 403) {
        form.setFieldError('currentPassword', I18n.t('common:form.errors.password.incorrect'));
      } else {
        message.error(I18n.t('common:modules.accountSettings.messages.changePassword.failure'));
      }
    })
    .finally(() => {
      form.setSubmitting(false);
    });
};

export const updateDetails = (values, form) => dispatch => {
  api
    .put('/update-details', values)
    .then(() => {
      message.success(I18n.t('common:modules.accountSettings.messages.updateDetails.success'));
      localStorage.setItem('user', values.name);
      dispatch(fetchAccountDetails());
    })
    .catch(error => {
      message.error(I18n.t('common:modules.accountSettings.messages.updateDetails.failure'));
    })
    .finally(() => {
      form.setSubmitting(false);
    });
};
