import { Button, Form, Input } from 'antd';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getFieldHasFeedback, getFieldHelp, getFieldValidateStatus } from 'utils/helpers';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 4 },
    xxl: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 9 },
    xl: { span: 8 },
    xxl: { span: 6 },
  },
};

const noLabelWrapperCol = {
  xs: { span: 24 },
  sm: { span: 10, offset: 8 },
  md: { span: 10, offset: 6 },
  lg: { span: 9, offset: 6 },
  xl: { span: 8, offset: 4 },
  xxl: { span: 6, offset: 3 },
};

const RenderForm = ({ handleSubmit, isSubmitting, isValid }) => {
  const { t } = useTranslation(['common']);

  return (
    <Form {...formItemLayout} onFinish={handleSubmit}>
      <Field name="currentPassword">
        {fieldProps => (
          <Form.Item
            hasFeedback={getFieldHasFeedback(fieldProps)}
            help={getFieldHelp(fieldProps)}
            label={t('form.labels.currentPassword')}
            validateStatus={getFieldValidateStatus(fieldProps)}
            required
          >
            <Input.Password {...fieldProps.field} autoComplete="current-password" />
          </Form.Item>
        )}
      </Field>
      <Field name="newPassword">
        {fieldProps => (
          <Form.Item
            hasFeedback={getFieldHasFeedback(fieldProps)}
            help={getFieldHelp(fieldProps)}
            label={t('form.labels.newPassword')}
            validateStatus={getFieldValidateStatus(fieldProps)}
            required
          >
            <Input.Password {...fieldProps.field} autoComplete="new-password" />
          </Form.Item>
        )}
      </Field>
      <Field name="newPasswordConfirmation">
        {fieldProps => (
          <Form.Item
            hasFeedback={getFieldHasFeedback(fieldProps)}
            help={getFieldHelp(fieldProps)}
            label={t('form.labels.newPasswordConfirmation')}
            validateStatus={getFieldValidateStatus(fieldProps)}
            required
          >
            <Input.Password {...fieldProps.field} autoComplete="new-password" />
          </Form.Item>
        )}
      </Field>
      <Form.Item wrapperCol={noLabelWrapperCol}>
        <Button disabled={!isValid} htmlType="submit" loading={isSubmitting} type="primary">
          {t('actions.changePassword')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RenderForm;
