import { useMediaQuery } from 'react-responsive';

export const useBreakpoints = () => {
  let breakpoint;

  if (useMediaQuery({ query: '(max-width: 575px)' })) {
    breakpoint = 'xs';
  }

  if (useMediaQuery({ query: '(min-width: 576px)' })) {
    breakpoint = 'sm';
  }

  if (useMediaQuery({ query: '(min-width: 768px)' })) {
    breakpoint = 'md';
  }

  if (useMediaQuery({ query: '(min-width: 992px)' })) {
    breakpoint = 'lg';
  }

  if (useMediaQuery({ query: '(min-width: 1200px)' })) {
    breakpoint = 'xl';
  }

  if (useMediaQuery({ query: '(min-width: 1600px)' })) {
    breakpoint = 'xxl';
  }

  return [breakpoint];
};
