import produce from 'immer';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  isAccountFetched: false,
  details: {
    attributes: {},
  },
};

export default handleActions(
  {
    [actions.setIsAccountFetched](state) {
      return produce(state, newState => {
        newState.isAccountFetched = true;
      });
    },
    [actions.setAccountDetails](state, { payload }) {
      return produce(state, newState => {
        newState.details = payload;
      });
    },
  },
  initialState
);
