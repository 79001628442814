import 'styled-components/macro';

import { Alert } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as selectors from '../../selectors';

const Messages = () => {
  const { t } = useTranslation(['common']);
  const isSettlementComplete = useSelector(selectors.getIsSettlementComplete);

  if (isSettlementComplete) {
    return null;
  }

  return (
    <Alert
      css={`
        margin-bottom: 2rem;
      `}
      description={t('modules.settlement.messages.settlementIncomplete.description')}
      message={t('modules.settlement.messages.settlementIncomplete.title')}
      type="error"
      showIcon
    />
  );
};

export default memo(Messages);
