import merge from 'deepmerge';
import produce from 'immer';
import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  isSettlementFetched: false,
  operations: {},
  options: {},
  fetchSettlementParams: {
    start: undefined,
    end: undefined,
    accommodationId: undefined,
  },
  isSettlementComplete: true,
  invoiceDetails: {
    issuingPossible: false,
  },
  summary: {},
};

export default handleActions(
  {
    [actions.setIsSettlementFetched](state, { payload }) {
      return produce(state, newState => {
        newState.isSettlementFetched = payload;
      });
    },
    [actions.setIsSettlementComplete](state, { payload }) {
      return produce(state, newState => {
        newState.isSettlementComplete = payload;
      });
    },
    [actions.setOperations](state, { payload }) {
      return produce(state, newState => {
        newState.operations = payload;
      });
    },
    [actions.setOptions](state, { payload }) {
      return produce(state, newState => {
        newState.options[payload.option] = payload.value;
      });
    },
    [actions.setSummary](state, { payload }) {
      return produce(state, newState => {
        newState.summary = payload;
      });
    },
    [actions.setInvoiceDetails](state, { payload }) {
      return produce(state, newState => {
        newState.invoiceDetails = payload;
      });
    },
    [actions.setSelectedAccommodationId](state, { payload }) {
      return produce(state, newState => {
        newState.fetchSettlementParams.accommodationId = payload;
      });
    },
    [actions.addFetchSettlementParams](state, { payload }) {
      return produce(state, newState => {
        newState.fetchSettlementParams = merge(state.fetchSettlementParams, payload);
      });
    },
  },
  initialState
);
