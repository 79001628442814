import 'styled-components/macro';

import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Menu } from 'antd';
import ColorHash from 'color-hash';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getInitials } from 'utils/helpers';

import { menuCss, subMenuCss } from './styled';

const colorHash = new ColorHash();

const UserMenu = ({ logout }) => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const userName = localStorage.getItem('user');
  const initials = userName ? getInitials(userName) : null;
  const color = colorHash.hex(userName);

  const handleMenuClick = useCallback(
    event => {
      switch (event.key) {
        case 'settings':
          return history.push('/account-settings');
        case 'logout':
          return logout();
        default:
          return null;
      }
    },
    [logout, history]
  );

  return (
    <Menu css={menuCss} mode="horizontal" selectable={false} forceSubMenuRender onClick={handleMenuClick}>
      <Menu.SubMenu
        key="user-menu"
        css={subMenuCss}
        title={
          <div>
            <Avatar
              css={`
                background-color: ${color};
                vertical-align: middle;
                margin-right: 1rem;
              `}
            >
              {initials}
            </Avatar>
            <span
              css={`
                font-weight: 400;
              `}
            >
              {userName}
            </span>
          </div>
        }
      >
        <Menu.Item key="settings">
          <SettingOutlined />
          <span>{t('modules.accountSettings.title')}</span>
        </Menu.Item>
        <Menu.Item key="logout">
          <LogoutOutlined />
          <span>{t('actions.logout')}</span>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default memo(UserMenu);
