import { message } from 'antd';
import I18n from 'i18n';
import normalize from 'json-api-normalizer';
import accommodations from 'modules/accommodations';
import { createAction } from 'redux-actions';
import api from 'services/api';

import * as types from './actionTypes';

export const setStays = createAction(types.SET_STAYS);
export const setIsCollectionFetched = createAction(types.SET_IS_COLLECTION_FETCHED);
export const setNonChargeableNights = createAction(types.SET_NON_CHARGEABLE_NIGHTS);
export const setSelectedAccommodationId = createAction(types.SET_SELECTED_ACCOMMODATION_ID);

export const setChangingStayBookingId = createAction(types.SET_CHANGING_STAY_BOOKING_ID);
export const resetChangingStayBookingId = createAction(types.RESET_CHANGING_STAY_BOOKING_ID);
export const setChangingStaySelectedAccommodationId = createAction(types.SET_CHANGING_STAY_SELECTED_ACCOMMODATION_ID);

export const fetchStays = () => dispatch => {
  api
    .get('/my-bookings')
    .then(({ data }) => {
      const normalizedData = normalize(data);

      if (data.data.length) {
        dispatch(setStays(normalizedData.booking));
      }

      dispatch(setNonChargeableNights(data.meta.nonChargeableNights));
      dispatch(setIsCollectionFetched(true));
    })
    .catch(() => {
      message.error(I18n.t('common:modules.stays.messages.fetchStays.failure'));
    });
};

export const fetchAccommodations = () => dispatch => {
  dispatch(accommodations.actions.setIsCollectionFetched(false));

  api
    .get('/accommodations')
    .then(({ data }) => {
      if (data.data.length) {
        const normalizedData = normalize(data);
        dispatch(accommodations.actions.setAccommodations(normalizedData.accommodation));
      }

      dispatch(accommodations.actions.setIsCollectionFetched(true));
    })
    .catch(() => {
      message.error(I18n.t('common:modules.accommodations.messages.fetchAccommodations.failure'));
    });
};

export const placeBooking =
  ({ values, form, onSuccess }) =>
  dispatch => {
    api
      .post('/my-bookings', values)
      .then(() => {
        message.success(I18n.t('common:modules.planStay.messages.placeBooking.success'));
        form.resetForm();
        onSuccess();
        dispatch(fetchAccommodations());
        dispatch(fetchStays());
      })
      .catch(() => {
        message.error(I18n.t('common:modules.planStay.messages.placeBooking.failure'));
      })
      .finally(() => {
        form.setSubmitting(false);
      });
  };

export const setNote = createAction(
  types.SET_NOTE,
  ({ id, content }) => ({
    id,
    content,
  }),
  ({ id, content }) => ({
    isOptimistic: true,
    url: `/my-bookings/${id}/set-note`,
    data: { note: content },
    method: 'POST',
  })
);

export const cancelStay = createAction(
  types.CANCEL_STAY,
  stay => stay,
  stay => ({
    isOptimistic: true,
    url: `/my-bookings/${stay.id}/cancel`,
    method: 'PUT',
  })
);

export const changeStay =
  ({ values, onSuccess, onEnd }) =>
  dispatch => {
    api
      .put(`/my-bookings/${values.id}/change-stay`, values)
      .then(() => {
        onSuccess();
        dispatch(fetchAccommodations());
        dispatch(fetchStays());
      })
      .catch(error => {
        message.error(I18n.t('common:modules.changeStay.messages.changeStay.failure'));
        console.error(error);
      })
      .finally(() => {
        onEnd();
      });
  };
