import 'styled-components/macro';

import { Alert, Button, DatePicker, Form, InputNumber, Modal, Select, Skeleton, Space, Spin, Statistic } from 'antd';
import locale from 'antd/es/date-picker/locale/pl_PL';
import accommodations from 'modules/accommodations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { formatMoney } from 'utils/helpers';

import { useCalendarChanging, useDisabledDateCalculation } from '../../../../app/components/DateRangePicker/hooks';
import * as selectors from '../../../selectors';
import * as hooks from '../hooks';
import { useForm, useFormObserver, useModal } from './hooks';
import {
  getChangingStayBooking,
  getInitialFormValues,
  getMaxNumAdults,
  getSelectedAccommodationAvailableDates,
} from './selectors';
import * as Styled from './styled';

const { RangePicker } = DatePicker;

const getPopupContainer = () => document.getElementsByClassName('accommodation-select')[0];

const ChangeStay = () => {
  const { t } = useTranslation(['common']);

  const booking = useSelector(getChangingStayBooking, shallowEqual);
  const availableDates = useSelector(getSelectedAccommodationAvailableDates, shallowEqual);
  const maxNumAdults = useSelector(getMaxNumAdults, shallowEqual);
  const initialFormValues = useSelector(getInitialFormValues, shallowEqual);
  const accommodationSelectOptions = useSelector(selectors.getAccommodationsSelectOptions, shallowEqual);
  const isAccommodationsCollectionFetched = useSelector(accommodations.selectors.getIsCollectionFetched, shallowEqual);

  const [isVisible, closeModal, width] = useModal();
  const [isStayPossible, status, serviceCharge, isCheckingStayPossibility, handleFormChange, resetPreview] =
    hooks.useStayPreview();
  const [form, valuesToPayload, handleSubmit, isSubmitting, reset] = useForm(isVisible, closeModal, resetPreview);
  const [onValuesChange] = useFormObserver(handleFormChange, valuesToPayload);
  const [handleCalendarChange, selectedDates, isCalendarTouched] = useCalendarChanging();
  const [calculateDisabledDate] = useDisabledDateCalculation(availableDates, selectedDates, isCalendarTouched);

  if (!booking || !Object.keys(initialFormValues).length) {
    return null;
  }

  return (
    <Modal
      footer={false}
      maskClosable={false}
      title={`${t('modules.changeStay.title')} - ${booking.number}`}
      visible={isVisible}
      width={width}
      onCancel={closeModal}
    >
      <Skeleton loading={!isAccommodationsCollectionFetched}>
        <Spin spinning={isCheckingStayPossibility}>
          <Form
            {...Styled.formItemLayout}
            form={form}
            initialValues={initialFormValues}
            preserve={false}
            onFinish={handleSubmit}
            onValuesChange={onValuesChange}
          >
            <Form.Item label={t('form.labels.accommodation')} name="accommodationId" rules={[{ required: true }]}>
              <Select className="accommodation-select" getPopupContainer={getPopupContainer} style={{ width: '100%' }}>
                {accommodationSelectOptions.map(({ title, value, disabled }) => (
                  <Select.Option key={value} disabled={disabled} value={value}>
                    {title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('form.labels.dates')}
              name="dates"
              rules={[{ required: true, message: t('modules.stays.messages.chooseDates') }]}
            >
              <RangePicker
                disabled={!availableDates.length}
                disabledDate={calculateDisabledDate}
                format="DD-MM-YYYY"
                locale={locale}
                mode={['date', 'date']}
                placeholder={[t('form.placeholders.arrival'), t('form.placeholders.departure')]}
                renderExtraFooter={() => (
                  <Styled.FooterContainer>
                    <Alert message={t('modules.stays.messages.chooseDatesHelp')} type="info" showIcon />
                  </Styled.FooterContainer>
                )}
                onCalendarChange={handleCalendarChange}
              />
            </Form.Item>
            <Form.Item
              label={t('form.labels.guestCount')}
              name="guestCount"
              rules={[{ required: true, type: 'number', min: 1, max: maxNumAdults }]}
            >
              <InputNumber max={maxNumAdults} min="1" />
            </Form.Item>
            {Object.keys(status).length ? (
              <Form.Item wrapperCol={Styled.noLabelWrapperCol}>
                <Alert message={status.message} type={status.alertType} showIcon />
              </Form.Item>
            ) : null}
            {serviceCharge ? (
              <Form.Item wrapperCol={Styled.noLabelWrapperCol}>
                <Statistic title={t('modules.stay.serviceCharge')} value={formatMoney(serviceCharge)} />
              </Form.Item>
            ) : null}
            {booking.cost && !serviceCharge ? (
              <Form.Item wrapperCol={Styled.noLabelWrapperCol}>
                <Statistic title={t('modules.stay.serviceCharge')} value={booking.cost} />
              </Form.Item>
            ) : null}
            <Form.Item wrapperCol={Styled.noLabelWrapperCol}>
              <Space>
                <Button disabled={!isStayPossible} htmlType="submit" loading={isSubmitting} type="primary">
                  {t('actions.change')}
                </Button>
                <Button disabled={!form.isFieldsTouched()} type="default" onClick={reset}>
                  {t('actions.revertChanges')}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Skeleton>
    </Modal>
  );
};

export default ChangeStay;
