import { Formik } from 'formik';
import React, { memo } from 'react';

import * as hooks from '../../hooks';
import RenderForm from './RenderForm';

const StayForm = ({ id, bookingId, closeModal }) => {
  const [handleSubmit, schema] = hooks.useForm(closeModal);

  return (
    <Formik
      initialValues={{
        stayId: id,
        id: bookingId,
      }}
      validationSchema={schema}
      enableReinitialize
      validateOnBlur
      validateOnChange
      onSubmit={handleSubmit}
    >
      {formProps => <RenderForm {...formProps} schema={schema} />}
    </Formik>
  );
};

export default memo(StayForm);
