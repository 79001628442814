import 'styled-components/macro';

import { Select } from 'antd';
import { noop } from 'lodash';
import accommodations from 'modules/accommodations';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

const AccommodationSelect = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const isAccommodationsCollectionFetched = useSelector(accommodations.selectors.getIsCollectionFetched, shallowEqual);
  const selectedAccommodationId = useSelector(selectors.getSelectedAccommodationId);
  const accommodationSelectOptions = useSelector(selectors.getAccommodationsSelectOptions);

  const handleAccommodationChange = useCallback(value => {
    dispatch(actions.setSelectedAccommodationId(value));
    dispatch(actions.setAccommodationInitialPeriodDates());
  }, []);

  const getPopupContainer = useCallback(() => {
    return document.getElementsByClassName('settlement-accommodation-select')[0];
  }, []);

  return (
    <Select
      key="settlement-accommodation-select"
      className="settlement-accommodation-select"
      css={`
        width: 100%;

        ${props => props.theme.breakpoints.lg} {
          width: 30rem !important;
        }
      `}
      disabled={!isAccommodationsCollectionFetched || accommodationSelectOptions.length <= 1}
      getPopupContainer={getPopupContainer}
      value={selectedAccommodationId}
      onBlur={noop}
      onChange={handleAccommodationChange}
    >
      <Select.Option value={0}>{t('allAccommodations')}</Select.Option>
      {accommodationSelectOptions.map(({ title, value, disabled }) => (
        <Select.Option key={value} disabled={disabled} value={value}>
          {title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default memo(AccommodationSelect);
