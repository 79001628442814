import { eachDayOfInterval, format, isAfter, isBefore, isSameDay, startOfDay, subDays } from 'date-fns';
import { sortBy, sortedUniq } from 'lodash';
import accommodations from 'modules/accommodations';
import moment from 'moment';
import { createSelector } from 'reselect';

export const getChangingStayBookingId = state => state.stays.current.changingStay.bookingId;
const getStaysMap = state => state.stays.current.myBookings;
const getSelectedAccommodationId = state => state.stays.current.changingStay.selectedAccommodationId;

export const getSelectedAccommodation = createSelector(
  [accommodations.selectors.getAccommodationsMap, getSelectedAccommodationId],
  (accommodationsMap, id) => {
    if (!id || !accommodationsMap[id]) {
      return null;
    }

    const accommodation = accommodationsMap[id];

    return {
      ...accommodation,
      attributes: {
        ...accommodation.attributes,
        availableDates: accommodation.attributes.availableDates.map(date => new Date(date)),
      },
    };
  }
);

export const getMaxNumAdults = createSelector(getSelectedAccommodation, accommodation => {
  if (!accommodation) {
    return null;
  }

  return accommodation.attributes.maxNumAdults;
});

export const getChangingStayBooking = createSelector([getStaysMap, getChangingStayBookingId], (staysMap, id) => {
  if (!id || !staysMap[id]) {
    return null;
  }

  return staysMap[id].attributes;
});

export const getSelectedAccommodationAvailableDates = createSelector(
  [getSelectedAccommodation, getChangingStayBooking],
  (accommodation, booking) => {
    if (!accommodation) {
      return [];
    }

    let availableDates = accommodation.attributes.availableDates || [];
    let minimumDate = startOfDay(new Date());
    const hasBookingBothDates = !!(!!booking && booking.arrival && booking.departure);

    if (hasBookingBothDates && booking.accommodationId == accommodation.id) {
      const arrival = new Date(booking.arrival);
      const departure = new Date(booking.departure);

      const bookingDates = eachDayOfInterval({
        start: arrival,
        end: subDays(departure, 1),
      });

      if (isBefore(arrival, minimumDate)) {
        minimumDate = startOfDay(arrival);
      }

      availableDates = [...availableDates, ...bookingDates];
    }

    return sortedUniq(
      sortBy(
        availableDates.filter(date => isSameDay(date, minimumDate) || isAfter(date, minimumDate)),
        date => date
      ).map(date => format(date, 'yyyy-MM-dd'))
    );
  }
);

export const getInitialFormValues = createSelector(getChangingStayBooking, booking => {
  if (!booking) {
    return {};
  }

  return {
    guestCount: booking.guestCount,
    accommodationId: booking.accommodationId,
    dates: [moment(booking.arrival), moment(booking.departure)],
  };
});
