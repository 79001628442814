import { Button, Form, Input, Row } from 'antd';
import { Field, Formik } from 'formik';
import I18n from 'i18n';
import app from 'modules/app';
import React, { memo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFieldHasFeedback, getFieldHelp, getFieldValidateStatus } from 'utils/helpers';

import * as actions from '../../actions';
import * as Styled from './styled';

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = I18n.t('common:login.form.email.errors.required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = I18n.t('common:login.form.email.errors.format');
  }

  if (!values.password) {
    errors.password = I18n.t('common:login.form.password.errors.required');
  }

  return errors;
};

const Login = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFormSubmit = useCallback(
    (values, form) => {
      dispatch(
        actions.signIn({
          data: values,
          history,
          cb: () => {
            form.setSubmitting(false);
          },
        })
      );
    },
    [history, dispatch]
  );

  return (
    <>
      <Helmet>
        <title>{`${t('appName')} - ${t('modules.login.title')}`}</title>
      </Helmet>
      <Styled.Container>
        <Styled.Content>
          <Styled.LogoContainer>
            <app.components.Logo />
          </Styled.LogoContainer>
          <Styled.FormContainer>
            <Formik
              initialErrors={{}}
              initialValues={{
                email: undefined,
                password: undefined,
              }}
              validate={validate}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onFinish={handleSubmit}>
                  <Field name="email">
                    {fieldProps => (
                      <Form.Item
                        hasFeedback={getFieldHasFeedback(fieldProps)}
                        help={getFieldHelp(fieldProps)}
                        validateStatus={getFieldValidateStatus(fieldProps)}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          {...fieldProps.field}
                          autoComplete="email"
                          placeholder={t('login.form.email.placeholder')}
                          type="email"
                        />
                      </Form.Item>
                    )}
                  </Field>
                  <Field name="password">
                    {fieldProps => (
                      <Form.Item
                        hasFeedback={getFieldHasFeedback(fieldProps)}
                        help={getFieldHelp(fieldProps)}
                        validateStatus={getFieldValidateStatus(fieldProps)}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input.Password
                          {...fieldProps.field}
                          autoComplete="current-password"
                          placeholder={t('login.form.password.placeholder')}
                        />
                      </Form.Item>
                    )}
                  </Field>
                  <Row>
                    <Button action="submit" htmlType="submit" loading={isSubmitting} size="large" type="primary">
                      {t('login.form.login')}
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </Styled.FormContainer>
        </Styled.Content>
      </Styled.Container>
    </>
  );
};

export default memo(Login);
