import { Formik } from 'formik';
import I18n from 'i18n';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import * as actions from '../../../actions';
import RenderForm from './RenderForm';

const schema = yup.object().shape({
  currentPassword: yup.string().required(I18n.t('common:form.errors.required')),
  newPassword: yup
    .string()
    .required(I18n.t('common:form.errors.required'))
    .min(8, I18n.t('common:form.errors.password.tooShort')),
  newPasswordConfirmation: yup
    .string()
    .required(I18n.t('common:form.errors.required'))
    .test('passwords-match', 'Hasła muszą być takie same', function (value) {
      return this.parent.newPassword === value;
    }),
});

const ChangePasswordForm = () => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (values, form) => {
      dispatch(actions.changePassword(values, form));
    },
    [dispatch]
  );

  return (
    <Formik
      initialValues={{
        currentPassword: undefined,
        newPassword: undefined,
        newPasswordConfirmation: undefined,
      }}
      validationSchema={schema}
      validateOnBlur
      onSubmit={handleSubmit}
    >
      {formProps => <RenderForm {...formProps} />}
    </Formik>
  );
};

export default memo(ChangePasswordForm);
