import { message } from 'antd';
import I18n from 'i18n';
import { all, call, takeEvery } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './actionTypes';

function* setNoteFailure() {
  yield call(message.error, I18n.t('common:modules.stays.messages.setNote.failure'));
}

function* cancelStayFailure() {
  yield call(message.error, I18n.t('common:modules.stays.messages.cancelStay.failure'));
}

function* cancelStaySuccess() {
  yield put(actions.fetchStays());
}

export default function* sagas() {
  yield all([
    takeEvery(types.CANCEL_STAY + '_FAILURE', cancelStayFailure),
    takeEvery(types.CANCEL_STAY + '_SUCCESS', cancelStaySuccess),
    takeEvery(types.SET_NOTE + '_FAILURE', setNoteFailure),
  ]);
}
