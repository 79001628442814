import { message } from 'antd';
import I18n from 'i18n';
import normalize from 'json-api-normalizer';
import { createAction } from 'redux-actions';
import api from 'services/api';

import * as types from './actionTypes';

export const setAccommodations = createAction(types.SET_ACCOMMODATIONS);
export const setIsCollectionFetched = createAction(types.SET_IS_COLLECTION_FETCHED);
export const setIds = createAction(types.SET_IDS);

export const fetchAccommodations = () => dispatch => {
  dispatch(setIsCollectionFetched(false));

  api
    .get('/accommodations')
    .then(({ data }) => {
      let accommodations = {};
      let ids = [];

      if (data.data.length) {
        const normalizedData = normalize(data);

        accommodations = normalizedData.accommodation;
        ids = Object.keys(accommodations);
      }

      dispatch(setAccommodations(accommodations));
      dispatch(setIds(ids));
      dispatch(setIsCollectionFetched(true));
    })
    .catch(() => {
      message.error(I18n.t('common:modules.accommodationn.messages.fetchAccommodations.failure'));
    });
};
