import { Table as AntTable, Tag } from 'antd';
import { format } from 'date-fns';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatMoney } from 'utils/helpers';

import { renderStatusOption } from '../../helpers';
import { getAccommodationFilterOptions, getBookings, getStatusFilterOptions } from '../../selectors';
import SettlementInclusionStatus from './SettlementInclusionStatus';

const StatusCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StatusContainer = styled.div`
  flex-grow: 2;
`;

const Table = ({ loading }) => {
  const { t } = useTranslation(['common']);
  const bookings = useSelector(getBookings);
  const accommodationFilterOptions = useSelector(getAccommodationFilterOptions);
  const statusFilterOptions = useSelector(getStatusFilterOptions);
  const renderDate = useCallback(dateText => format(new Date(dateText), 'dd-MM-yyyy'), []);

  const renderMoneyOrOriginType = useCallback(
    (text, record) =>
      text ? formatMoney(text, record.currency) : <Tag>{t('modules.bookingsList.table.employeeOriginType')}</Tag>,
    [t]
  );

  const columns = [
    {
      title: t('modules.bookingsList.table.status'),
      dataIndex: 'status',
      width: 250,
      key: 'status',
      render: (text, { settlementInclusionStatus }) => (
        <StatusCell>
          <StatusContainer>{renderStatusOption(text, t)}</StatusContainer>
          <SettlementInclusionStatus
            status={settlementInclusionStatus}
            tooltip={t(`modules.bookingsList.inclusionStatusHints.${settlementInclusionStatus}`)}
          />
        </StatusCell>
      ),
      onFilter: (value, record) => record.status === value,
      filters: statusFilterOptions.map(statusOption => ({
        text: renderStatusOption(statusOption, t),
        value: statusOption,
      })),
    },
    {
      title: t('modules.bookingsList.table.accommodationName'),
      dataIndex: 'accommodationName',
      key: 'accommodationName',
      width: 'auto',
      ellipsis: true,
      filters: accommodationFilterOptions,
      onFilter: (value, record) => record.accommodationName.indexOf(value) === 0,
    },
    {
      title: t('modules.bookingsList.table.bookingValue'),
      dataIndex: 'bookingValue',
      key: 'bookingValue',
      width: 140,
      align: 'right',
      render: renderMoneyOrOriginType,
    },
    {
      title: t('modules.bookingsList.table.arrival'),
      dataIndex: 'arrival',
      key: 'arrival',
      width: 150,
      render: renderDate,
    },
    {
      title: t('modules.bookingsList.table.departure'),
      dataIndex: 'departure',
      key: 'departure',
      width: 150,
      render: renderDate,
    },
    {
      title: t('modules.bookingsList.table.guestCount'),
      dataIndex: 'guestCount',
      key: 'guestCount',
      align: 'center',
      width: 150,
    },
  ];

  return (
    <AntTable
      columns={columns}
      dataSource={bookings}
      loading={loading}
      pagination={{ position: 'both', pageSize: 25 }}
      scroll={{ x: 'max-content' }}
      size="middle"
    />
  );
};

export default memo(Table);
