import 'styled-components/macro';

import { Descriptions, Spin, Typography } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import * as selectors from '../../selectors';
import Amount from './Amount';

const Summary = ({ loading }) => {
  const { t } = useTranslation(['common']);
  const summary = useSelector(selectors.getSummary, shallowEqual);

  if (!summary) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Descriptions
        column={3}
        css={`
          margin-bottom: 3rem;

          .ant-descriptions-item-label {
            white-space: normal;
          }
        `}
        layout="vertical"
        size="small"
        title={t('modules.settlement.summary.title')}
        bordered
      >
        <Descriptions.Item
          css={`
            white-space: nowrap;
          `}
          label={t('modules.settlement.summary.bookings')}
        >
          <Amount currency="PLN" direction="in" value={summary.bookingsDueSubtotal} noSign />
        </Descriptions.Item>
        <Descriptions.Item label={t('modules.settlement.summary.expenditure')}>
          <Amount currency="PLN" direction="out" value={summary.expenditureDueSubtotal} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <Typography.Text strong>{t('modules.settlement.summary.total')}</Typography.Text>
              <br />
              <small>
                <Typography.Text type="secondary">({t('modules.settlement.summary.totalDescription')})</Typography.Text>
              </small>
            </>
          }
        >
          <Amount currency="PLN" direction={summary.totalPositive ? 'in' : 'out'} value={summary.dueTotal} noSign />
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  );
};

export default memo(Summary);
