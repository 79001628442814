import { addDays, eachDayOfInterval, format, isAfter, isSameDay } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

const getlastBookable = dates => {
  const unixDay = 24 * 60 * 60;
  const datesDays = dates.map(date => new Date(date).getTime() / 1000);

  let i;

  for (i = 1; i < dates.length; i += 1) {
    if (datesDays[i] - datesDays[i - 1] !== unixDay) {
      break;
    }
  }

  return dates[i - 1];
};

export const useCalendarOpening = onOpenChange => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleOpenChange = useCallback(status => {
    setIsCalendarOpen(status);
    onOpenChange(status);
  }, []);

  return [isCalendarOpen, handleOpenChange];
};

export const useCalendarChanging = (initialDates = []) => {
  const [selectedDates, selectDates] = useState(initialDates);
  const [touched, setTouched] = useState(false);

  const handleCalendarChange = useCallback(
    dates => {
      selectDates(dates);
      setTouched(true);
    },
    [setTouched]
  );

  return [handleCalendarChange, selectedDates, touched];
};

export const useDisabledDateCalculation = (availableDates, selectedDates, isCalendarTouched) => {
  const dates = selectedDates || [];
  const startValue = useMemo(() => (dates[0] ? new Date(dates[0]) : new Date()), [dates]);
  const availableDatesScope = availableDates.map(availableDate => new Date(availableDate));
  const datesFromStartToLastAvailable = availableDatesScope.filter(dateFromScope => isAfter(dateFromScope, startValue));
  const lastBookable = addDays(getlastBookable(datesFromStartToLastAvailable), 1); // Because end is possible on a booked date (half-days)
  const onlyStartSelected = !!dates[0] && !dates[1];
  const startAndEndSelected = !!dates[0] && !!dates[1];

  const datesScope = useMemo(() => {
    if (onlyStartSelected) {
      return eachDayOfInterval({
        start: startValue,
        end: lastBookable,
      });
    } else if (isCalendarTouched && startAndEndSelected) {
      return [...availableDatesScope, lastBookable];
    } else {
      return availableDatesScope;
    }
  }, [availableDatesScope, isCalendarTouched, startAndEndSelected, lastBookable, startValue]);

  const calculateDisabledDate = useCallback(
    date => {
      return !datesScope.map(dateFromScope => format(dateFromScope, 'yyyy-MM-dd')).includes(date.format('YYYY-MM-DD'));
    },
    [datesScope]
  );

  return [calculateDisabledDate];
};
