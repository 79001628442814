import { Badge } from 'antd';
import React from 'react';

const statusesMap = {
  placed: 'processing',
  confirmed: 'success',
  expired: 'warning',
  canceled_with_cost: 'error',
  canceled: 'error',
  canceled_without_cost: 'error',
  completed: 'default',
};

export const renderStatusOption = (status, t) => (
  <Badge
    key={status}
    status={statusesMap[status]}
    style={{ width: 'max-content' }}
    text={t(`modules.bookingsList.statuses.${status}`)}
  />
);
