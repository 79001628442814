import 'styled-components/macro';

import { Card, Col, Divider, Form, Row } from 'antd';
import app from 'modules/app';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import AccommodationSelect from './AccommodationSelect';
import InvoiceDetails from './InvoiceDetails';
import Messages from './Messages';
import Periods from './Periods';
import Summary from './Summary';
import Table from './Table';

const Layout = app.components.Layout;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const List = () => {
  const { t } = useTranslation(['common']);

  const fetchSettlementParams = useSelector(selectors.getFetchSettlementParams, shallowEqual);
  const isSettlementFetched = useSelector(selectors.getIsSettlementFetched, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchAccommodations());
  }, [dispatch]);

  useEffect(() => {
    const { start, end, accommodationId } = fetchSettlementParams;

    if (start && end && accommodationId !== undefined && accommodationId !== null) {
      dispatch(actions.fetchSettlement());
    }
  }, [fetchSettlementParams, dispatch]);

  return (
    <>
      <Helmet>
        <title>{`${t('appName')} - ${t('modules.settlement.title')}`}</title>
      </Helmet>
      <Layout.PageHeader title={t('modules.settlement.title')} />
      <Layout.ContentInner>
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              <Form
                css={`
                  margin-bottom: 1rem;
                `}
                layout="vertical"
              >
                <Form.Item label={t('modules.settlement.form.labels.period')}>
                  <Periods />
                </Form.Item>

                <Form.Item label={t('modules.settlement.form.labels.accommodation')}>
                  <AccommodationSelect />
                </Form.Item>
              </Form>
              <Messages />
              <Table loading={!isSettlementFetched} />
              <Divider dashed />
              <Row gutter={20}>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <InvoiceDetails loading={!isSettlementFetched} />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Summary loading={!isSettlementFetched} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Layout.ContentInner>
    </>
  );
};

export default memo(List);
