import Cookies from 'js-cookie';
import auth from 'modules/auth';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const enhance = compose(withRouter, connect(null, auth.actions));

class RouterListener extends Component {
  constructor(props) {
    super(props);

    this.verifyAuth();
    this.setRedirectTo();

    props.history.listen(() => {
      this.verifyAuth();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setRedirectTo(prevProps.location.pathname);
    }
  }

  verifyAuth = () => {
    const { authUser, unAuthUser } = this.props;

    if (Cookies.get('revenuo-owner-jwt')) {
      authUser();
    } else {
      unAuthUser();
    }
  };

  setRedirectTo = (path = null) => {
    const {
      setRedirectTo,
      history: {
        location: { pathname },
      },
    } = this.props;

    setRedirectTo(path || pathname);
  };

  render() {
    return <>{this.props.children}</>;
  }
}

export default enhance(RouterListener);
