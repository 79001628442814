import 'styled-components/macro';

import { Col, Row } from 'antd';
import app from 'modules/app';
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import Item from './Item';

const Layout = app.components.Layout;

const List = () => {
  const { t } = useTranslation(['common']);

  const isCollectionFetched = useSelector(selectors.getIsCollectionFetched, shallowEqual);
  const accommodations = useSelector(selectors.getAccommodationsWithOngoingAgreement, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchAccommodations());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{`${t('appName')} - ${t('modules.accommodations.title')}`}</title>
      </Helmet>
      <Layout.PageHeader title={t('modules.accommodations.title')} />
      <Layout.ContentInner>
        <Row gutter={20} type="flex">
          {accommodations.map(accommodation => (
            <Col key={accommodation.slug} lg={8} md={12} sm={24} xl={6} xs={24}>
              <Item item={accommodation} />
            </Col>
          ))}
        </Row>
      </Layout.ContentInner>
    </>
  );
};

export default memo(List);
